<!--
  Page template to be use for Submit Claim pages.
-->
<template>
	<BRow v-if="!loading" v-bind="$attrs">
		<BCol :lg="isHsaScreen ? '12' : '9'">
			<ClaimPageHeader
				:title="`${$t('pageTitle')} - ${claimType}`"
				automation-id="submitClaimHeader"
			></ClaimPageHeader>
			<BCol class="p-0" :lg="!isHsaScreen ? '12' : '9'">
				<WizardProgressBar
					:percent="percentComplete"
					automation-id="submitClaimProgressBar"
				></WizardProgressBar>
			</BCol>
			<WizardStepHeader :title="stepTitle" automation-id="claimStepHeader"></WizardStepHeader>

			<BRow>
				<BCol cols="12" :md="fluid ? '12' : '9'">
					<slot></slot>
				</BCol>
			</BRow>
		</BCol>
	</BRow>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';
// mixins
import IdMixin from '@/mixins/id';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
// components
import ClaimPageHeader from '@/components/submit-claim/ClaimPageHeader.vue';
import WizardProgressBar from '@/components/wizard/WizardProgressBar.vue';
import WizardStepHeader from '@/components/wizard/WizardStepHeader.vue';
import { SUBMIT_CLAIM, DASHBOARD } from '@/constants/Routes.js';

// models
import Claims from '@/models/claims/Claims';

export default {
	name: 'SubmitClaimTemplate',
	components: { BRow, BCol, ClaimPageHeader, WizardProgressBar, WizardStepHeader },
	mixins: [IdMixin, BreadcrumbsManager],
	inheritAttrs: false,
	props: {
		percentComplete: {
			type: Number,
			required: true,
			validator: (percent) => percent >= 0 && percent <= 100
		},
		stepTitle: {
			type: String,
			required: true
		},
		isHsaScreen: {
			type: Boolean,
			default: false
		},
		fluid: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			claimType: null,
			loading: false
		};
	},
	async created() {
		this.loading = true;
		await this.getClaimTypes();
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.submitClaim', 'en'), to: { name: SUBMIT_CLAIM.CLAIM_TYPE } },
				{ text: this.claimType }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.submitClaim', 'fr'), to: { name: SUBMIT_CLAIM.CLAIM_TYPE } },
				{ text: this.claimType }
			]
		);
		this.loading = false;
	},
	methods: {
		async getClaimTypes() {
			this.claimType = await Claims.getClaimType(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$root.$i18n.locale
			);
			// Check if the claim typoe is null, if it is then set it to the health claim type value.
			// This will be used for non-RTA members since no claimtypes should be returned for them.
			if (!this.claimType) {
				this.claimType = this.$t('health');
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.progress-bar {
	width: 70%;
}
</style>
<i18n>
{
	"en": {
		"pageTitle": "Submit a Claim",
		"health": "Health (General)",
		"breadcrumb": {
			"home": "Home",
			"submitClaim": "Submit a Claim"
    	}
	},
	"fr": {
		"pageTitle": "Soumettre une demande",
		"health": "Santé (Général)",
		 "breadcrumb": {
			"home": "Accueil",
			"submitClaim": "Soumettre une demande"
    	}
	}
}
</i18n>
