<!--/**
 * This component is called to show when the user does not have any HSA funds
 */-->
<template>
	<ErrorModal
		class="hsa-fund-modal"
		:modal-show="modalShow"
		:has-close-button="false"
		:cant-close-on-esc="true"
		no-hide-on-blur
		:error-title="$t('message.title')"
		:error-message="$t('message.body.part1')"
	>
		<BaseButton
			:label="$t('button.ok')"
			:automation-id="'btn-ok'"
			variant="primary"
			@click="onClick"
		/>
	</ErrorModal>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ErrorModal from '@/components/common/ErrorModal';
import BaseButton from '@/components/common/base/BaseButton';
import * as Routes from '@/constants/Routes.js';
@Component({
	name: 'HsaFundError',
	components: {
		ErrorModal,
		BaseButton
	},
	props: {
		modalShow: {
			type: Boolean,
			default: false
		}
	}
})
export default class HsaFundError extends Vue {
	onClick() {
		this.$router.push({ name: Routes.CLAIM_TYPE });
	}
}
</script>
<style lang="scss" scoped>
.hsa-fund-modal ::v-deep(.modal-content) {
	width: 100%;
}
.hsa-fund-modal ::v-deep(.error-modal-dialog) {
	max-width: 610px;
}
</style>
<i18n>
{
    "en": {
		"message": {
			"title": "No HSA Funds Available",
			"body": {
				"part1": "This claim cannot be processed since all the HSA funds available in your account have been used."
			}
		},
		"button": {
			"ok": "OK"
		}
    },
    "fr": {
		"message" : {
			"title": "Aucun fonds dans le CGS",
			"body": {
				"part1":"Cette demande de règlement ne peut être trai tée puisque tous les fonds de votre CGS ont été utilisés."
			}
		},
		"button": {
			"ok": "OK"
		}
	}
}
</i18n>
