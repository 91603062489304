<!-- eslint-disable @intlify/vue-i18n/no-v-html vue/no-v-html 
	** this is required to help bold certain words that is inside i18n only and should not be used with user inputs
-->
<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<span>
		<BaseButton
			:pill="false"
			class="terms-button"
			variant="link"
			type="button"
			:automation-id="getAutomationId('terms-and-conditions')"
			:label="$t('termsAndConditions')"
			@click="toggleModal()"
		/>

		<BaseModal
			class="terms-modal"
			:modal-show="toggleModalShow"
			:is-static="true"
			:modal-title="title"
			:automation-id="getAutomationId('terms-and-conditions')"
			@change="closeModal()"
		>
			<template #contentBody>
				<TermsAndConditionsJsonContent
					:hide-title="true"
					:terms-and-condition="termsAndCondition"
					automation-id="termsAndConditions"
				/>
				<BaseButton
					class="close-button"
					variant="primary"
					type="button"
					:automation-id="getAutomationId('terms-and-conditions')"
					:label="$t('close')"
					@click="toggleModal()"
				/>
			</template>
		</BaseModal>
	</span>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import IdMixin from '@/mixins/id';
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseModal from '@/components/common/base/BaseModal';
import BaseButton from '@/components/common/base/BaseButton';
import TermsAndConditionsJsonContent from '@/components/termsAndConditions/TermsAndConditionJsonContent';

// @vue/component
@Component({
	name: 'TermsAndConditionModal',
	components: {
		BaseModal,
		BaseButton,
		TermsAndConditionsJsonContent
	},
	mixins: [IdMixin],
	props: {
		/** Used by the test automation suite and the Google tag manager to identify components. */
		automationId: {
			type: String,
			required: true
		},
		termsAndCondition: {
			type: Object,
			required: true,
			default: null
		},
		title: {
			type: String,
			default: null
		}
	}
})
export default class TermsAndConditionModal extends Vue {
	toggleModalShow = false;

	toggleModal() {
		this.toggleModalShow = !this.toggleModalShow;
	}
	closeModal() {
		this.toggleModalShow = false;
	}
}
</script>

<!-- *************************************************************************
	STYLES
	************************************************************************* -->
<style lang="scss" scoped>
.terms-button {
	padding: 0;
}

.terms-modal ::v-deep .modal-dialog {
	margin-top: 10%;
	@media (max-width: 576px) {
		margin-top: 15%;
	}
}
.terms-modal ::v-deep .modal-header {
	margin-left: 30px;
	margin-bottom: 20px;
}

.terms-modal ::v-deep .modal-body {
	padding: 0px;
	margin-bottom: 0;
}
.close-button {
	margin-top: 30px;
	margin-bottom: 30px;
}
</style>
<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
    "en": {
        "termsAndConditions": "Terms & Conditions",
		"close": "Close"
    },
    "fr": {
         "termsAndConditions": "modalités et les conditions",
		 "close": "Fermer"
    }

}
</i18n>
