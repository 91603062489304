<template>
	<SubmitClaimTemplate
		v-if="!$store.state.loading"
		:percent-complete="21"
		:step-title="$t('title')"
		:fluid="false"
	>
		<BaseForm v-slot="{ invalid }" automation-id="claimAccidentForm'" class="verify-info">
			<p>{{ $t('subtitle') }}</p>

			<BaseCard v-if="isDisabilityOrLife()" :title="memberName" title-tag="h3">
				<MailingAddressDisplay
					v-if="contactInfo"
					automation-id="member-address"
					:address-line1="contactInfo.addressLine1"
					:address-line2="contactInfo.addressLine2"
					:address-line3="contactInfo.addressLine3"
					:city="contactInfo.city"
					:province="contactInfo.province"
					:state="contactInfo.state"
					:postal-code="contactInfo.postalCode"
					:zip-code="contactInfo.zipCode"
					:country="contactInfo.country"
				></MailingAddressDisplay>

				<BaseDisplayGroup v-if="phoneNumber" automation-id="memberPhone" :label="$t('phone')">
					<address>{{ phoneNumber }}</address>
				</BaseDisplayGroup>

				<BaseButton
					class="p-0"
					automation-id="verify-personal-dci-edit"
					variant="link"
					:label="$t('editButton')"
					@click="launchNavModal('personal-info')"
				/>
			</BaseCard>
			<VerifyPersonalInfoCard
				v-else
				title-tag="h3"
				:automation-id="getAutomationId('verify-info-personal')"
				:member-name="memberName"
				:email="email"
				:phone-number="phoneNumber"
				@editClick="launchNavModal('personal-info')"
			/>

			<VerifyBankingCard
				title-tag="h3"
				:automation-id="getAutomationId('verify-info-banking')"
				:masked-banking="maskedAccountNumber"
				@editClick="launchNavModal"
			/>

			<BaseCard v-if="isDisabilityOrLife()" title-tag="h3">
				<BCardTitle>
					{{ $t('email.title')
					}}<InfoModal
						class="email-modal d-inline-block"
						automation-id="account-email-modal"
						:modal-title="$t('emailModal.title')"
					>
						<p>{{ $t('emailModal.content') }}</p>
					</InfoModal>
				</BCardTitle>

				<BaseDisplayGroup v-if="email" automation-id="memberPhone" :label="$t('email.label')">
					<address>{{ email }}</address>
				</BaseDisplayGroup>

				<BaseButton
					class="p-0"
					automation-id="verify-email-dci-edit"
					variant="link"
					:label="$t('editButton')"
					@click="launchNavModal('email')"
				/>
			</BaseCard>

			<WizardButtonBar
				:disable-next="invalid"
				:next-button-label="$t('next')"
				@navigate-back="navigateBack()"
				@navigate-next="navigateNext()"
			></WizardButtonBar>
		</BaseForm>
		<NavigationModal
			:show-modal="showNavModal"
			automation-id="navigation-modal"
			:error-title="modalTitle"
			:error-text="modalText"
			:button-question="modalQuestion"
			:first-button-label="$t('navigationModal.leftButton')"
			:second-button-label="modalYesButton"
			:navigation-route="navigationRoute"
			@closeModal="closeNavModal"
		/>
	</SubmitClaimTemplate>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { required } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
// pages
import SubmitClaimTemplate from '@/pages/submit-claim/SubmitClaimTemplate.vue';
// models
import Page from '@/models/claims/Page';
import ContactInfo from '@/models/ContactInfo';
import Dependent from '@/models/Dependent.js';
import DirectDepositInfoCall from '@/models/DirectDepositInfoCall.js';
import MemberCardInfo from '@/models/MemberCardInfo';
// components
import { BCardTitle } from 'bootstrap-vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseDisplayGroup from '@/components/common/base/BaseDisplayGroup.vue';
import BaseForm from '@/components/common/base/BaseForm';
import InfoModal from '@/components/common/InfoModal.vue';
import MailingAddressDisplay from '@/components/common/MailingAddressDisplay.vue';
import NavigationModal from '@/components/common/NavigationModal';
import VerifyBankingCard from '@/components/claim/card/VerifyBankingCard';
import VerifyPersonalInfoCard from '@/components/claim/card/VerifyPersonalInfoCard';
import WizardButtonBar from '@/components/wizard/WizardButtonBar.vue';
// constants
import { SUBMIT_CLAIM, CHANGE_EMAIL, PERSONAL_INFORMATION } from '@/constants/Routes.js';
import * as claimTypes from '@/constants/ClaimTypes.js';
// utils
import { formatPhoneNumberWithParentheses } from '@/utils/commonUtils.js';

extend('required', required);

// @vue/component
@Component({
	name: 'VerifyInformationPage',
	components: {
		VerifyPersonalInfoCard,
		VerifyBankingCard,
		WizardButtonBar,
		BaseForm,
		SubmitClaimTemplate,
		BaseCard,
		NavigationModal,
		BaseButton,
		BaseDisplayGroup,
		MailingAddressDisplay,
		InfoModal,
		BCardTitle
	},
	mixins: [IdMixin],
	props: {},
	watch: {
		locale() {
			this.getResourceUrls();
		}
	}
})
export default class VerifyInformationPage extends Vue {
	pageName = 'verify-information';
	contactInfo = null;
	member = null;
	phoneNumber = null;
	maskedAccountNumber = null;
	checkBox = false;
	claim = {};
	pageTitle = '';
	navigationRoute = null;
	modalTitle = null;
	modalText = null;
	modalQuestion = null;
	modalYesButton = null;
	showNavModal = false;

	get email() {
		return sessionStorage.getItem('email');
	}

	get locale() {
		return this.$root.$i18n.locale;
	}

	get memberName() {
		return `${this.member?.firstName} ${this.member?.lastName}`;
	}

	/**
	 * before mounting retrieve all the members info.
	 */
	async created() {
		this.$store.dispatch('updateLoading', true);
		await this.getOrCreatePage();
		await this.getMemberBanking();
		await this.getMemberInfo();
		this.$store.dispatch('updateLoading', false);
	}

	isDisabilityOrLife() {
		return this.claim?.claimType === claimTypes.DCI || this.claim?.claimType === claimTypes.LADD;
	}

	/**
	 * This method retrieves all the members personal info. It will grab the phone number,
	 * and the members name from the API, or locally if it has been called before.
	 */
	async getMemberInfo() {
		this.contactInfo = await ContactInfo.getUserInfo(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.locale
		);
		this.member = await MemberCardInfo.getMemberDetail(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.locale
		);
		const participant = await Dependent.getDependents(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.locale
		);
		if (this.contactInfo?.phone) {
			this.phoneNumber = formatPhoneNumberWithParentheses(this.contactInfo.phone);
		}
		if (this.claim) {
			this.claim.dateOfBirth = participant?.data[0]?.dateOfBirth;
		}
	}

	/**
	 * This method grabs the members banking info and masks
	 * it to be displayed to the member.
	 */
	async getMemberBanking() {
		let response = await DirectDepositInfoCall.getDirectDepositInfo(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		);
		if (response?.data) {
			this.maskedAccountNumber = response?.data?.account.replace(/.(?=.{3,}$)/g, '*');
		}
	}

	updateChecked(value) {
		if (value === true) {
			this.checkBox = value;
		} else {
			this.checkBox = null;
		}
	}

	emailClick() {
		this.$router.push({ name: CHANGE_EMAIL });
	}

	async launchNavModal(args) {
		this.showNavModal = true;
		this.$store.dispatch('isOkToLeaveClaimSubmission', true);
		if (typeof args === 'string') {
			if (args === 'personal-info') {
				this.navigationRoute = PERSONAL_INFORMATION;
				// French translation is different for life and disability claims
				if (this.isDisabilityOrLife()) {
					this.modalTitle = this.$t('navigationModal.personalInfo.titleLife');
					this.modalYesButton = this.$t('navigationModal.rightButton');
					this.modalQuestion = this.$t('navigationModal.personalInfo.questionLife');
					this.modalText = this.$t('navigationModal.personalInfo.textLife');
				} else {
					this.modalTitle = this.$t('navigationModal.personalInfo.title');
					this.modalYesButton = this.$t('navigationModal.rightButtonTwo');
					this.modalQuestion = this.$t('navigationModal.personalInfo.question');
					this.modalText = this.$t('navigationModal.personalInfo.text');
				}
			} else if (args === 'email') {
				this.navigationRoute = CHANGE_EMAIL;
				this.modalTitle = this.$t('navigationModal.email.title');
				this.modalText = this.$t('navigationModal.email.text');
				this.modalQuestion = this.$t('navigationModal.email.question');
				this.modalYesButton = this.$t('navigationModal.rightButtonTwo');
			}
		} else {
			// The only args left is banking so we default to those values if personal-info and email were not found.
			this.navigationRoute = args[0];
			this.modalTitle = this.$t('navigationModal.banking.title');
			this.modalText = this.$t('navigationModal.banking.text');
			this.modalQuestion = this.$t('navigationModal.banking.question');
			this.modalYesButton = this.$t('navigationModal.rightButton');
		}
	}

	closeNavModal() {
		this.showNavModal = false;
	}
	async navigateBack() {
		Page.deleteById(this.pageName);
	}

	async navigateNext() {
		try {
			await Page.updatePage(this.page, this.claim);
			if (this.isDisabilityOrLife() || this.claim.claimType === claimTypes.TRAVEL) {
				this.$router.push({
					name: SUBMIT_CLAIM.ATTACH_DOCUMENTS
				});
			} else if (
				this.claim.claimType === claimTypes.HEALTH ||
				this.claim.claimType === claimTypes.PWA ||
				this.claim.claimType === claimTypes.PRACTITIONER ||
				this.claim.claimType === claimTypes.VISION ||
				this.claim.claimType === claimTypes.DENTAL ||
				this.claim.claimType === claimTypes.DRUGS
			) {
				this.$router.push({
					name: SUBMIT_CLAIM.PROVIDER_TYPE_CHOICE
				});
			} else if (this.claim.claimType === claimTypes.HSA) {
				this.$router.push({
					name: SUBMIT_CLAIM.HSA_ELIGIBLE_CLAIMS
				});
			}
		} catch (error) {
			this.$store.dispatch('error', {
				name: 'generic'
			});
		}
	}

	/**
	 * This method will check to see if a page object already exists for this screen, if it does it will retrieve it and update the claim object with the value it has for the claims.
	 * If no page exists it will create a new one.
	 */
	async getOrCreatePage() {
		this.page = await Page.getPageById(this.pageName);
		if (this.page === null) {
			this.claim = await Page.getClaimFromLastPage();
			this.page = await Page.createNewPage(this.pageName, sessionStorage.getItem('email'));
		} else {
			this.claim = this.page?.claims[0];
		}
	}
}
</script>

<style lang="scss" scoped>
.verify-info ::v-deep(.display-group) {
	margin-top: 0px;
}
</style>
<i18n>
{
  "en": {
	  "title": "Verify Information",
	  "subtitle": "Is this your most up-to-date information?",
	  "next": "Next",
	  "editButton": "Edit",
	  "phone": "Phone",
	  "email": { 
		"title": "Account email",
		"label": "Email"
	  },
	  "emailModal": {
		"title": "This is your account login email",
		"content": "Your account can be associated with only one email address at a time. If you change the email address for this claim, your new email will be used to log in."
	  },
	  "navigationModal": {
		"personalInfo": {
				"title": "Need to update your personal information?",
				"titleLife": "Need to update your personal information?",
				"text": "No problem! To update this, you'll need to exit the claim submission process and update your information under 'My Account'.",
				"textLife": "No problem! To update this, you'll need to exit the claim submission process and update your information under 'My Account'.",
				"question": "Do you want to abandon your claim for now and update your personal information?",
				"questionLife": "Do you want to abandon your claim for now and update your personal information?"
			},
			"email": {
				"title": "Need to update your email address?",
				"text": "No problem! To update this, you’ll need to exit the claim submission process and update your information under ‘My Account’.",
				"question": "Do you want to abandon your claim for now and update your email address?"
			},
			"banking": {
				"title": "Need to update your direct deposit information?",
				"text": "No problem! To update your banking information, you'll need to exit the claim submission process and update your information under 'My Account'.",
				"question": "Do you want to abandon your claim for now and update your direct deposit information?"
			},
			"leftButton": "No thanks",
			"rightButton": "Yes, I want to update",
			"rightButtonTwo": "Yes, I want to update"
	  }
  },
  "fr": {
		"title": "Vérifier les renseignements",
		"subtitle": "S'agit-il de vos coordonnées les plus récentes?",
		"next": "Suivant",
		"editButton": "Modifier",
		"phone": "Téléphone",
		"email": { 
			"title": "Adresse courriel du compte",
			"label": "Courriel"
		},
		"emailModal": {
			"title": "Voici l'adresse courriel associée à votre compte",
			"content": "Votre compte peut seulement être associé à une seule adresse courriel à la fois. Si vous modifiez l'adresse courriel pour cette demande de prestations, cette nouvelle adresse servira aussi à ouvrir votre session."
		},
		"navigationModal": {
			"personalInfo": {
				"title": "Vous devez mettre à jour vos coordonnées?",
				"titleLife": "Vous devez mettre à jour vos renseignements personnels?",
				"text": "Aucun problème! Pour les mettre à jour, vous devez quitter le processus de soumission de demande de règlement et mettre à jour vos renseignements à la section Mon compte.",
				"textLife": "Aucun problème! Pour les mettre à jour, vous devez quitter le processus de soumission de demande de prestations et mettre à jour vos renseignements à la section Mon compte.",
				"question": "Voulez-vous abandonner pour le moment votre demande de règlement et mettre à jour vos coordonnées?",
				"questionLife": "Voulez-vous abandonner pour le moment votre demande de prestations et mettre à jour vos renseignements personnels?"

			},
			"email": {
				"title": "Vous devez mettre à jour votre adresse courriel?",
				"text": "Aucun problème! Pour la mettre à jour, vous devez quitter le processus de soumission de demande de prestations et mettre à jour vos renseignements à la section Mon compte.",
				"question": "Voulez-vous abandonner pour le moment votre demande de prestations et mettre à jour votre adresse courriel?"
			},
			"banking": {
				"title": "Vous voulez mettre à jour les renseignements pour le dépôt direct?",
				"text": "Aucun problème! Pour les mettre à jour, vous devez quitter le processus de soumission de demande de règlement et mettre à jour vos renseignements à la section Mon compte.",
				"question": "Voulez-vous abandonner pour le moment votre demande de règlement et mettre à jour vos renseignements sur le dépôt direct? "
			},
			"leftButton": "Non, merci",
			"rightButton": "Oui, je veux les mettre à jour",
			"rightButtonTwo": "Oui, je veux la mettre à jour"
	  }
  }
}
</i18n>
