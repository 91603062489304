<template>
	<h2
		:id="getUniqueId('wizardStepHeader')"
		:data-test-automation-id="getAutomationId('wizardStepHeader')"
		:data-tracking-id="getAutomationId('wizardStepHeader')"
	>
		{{ title }}
	</h2>
</template>

<script>
import IdMixin from '@/mixins/id';

export default {
	name: 'WizardStepHeader',
	mixins: [IdMixin],
	props: {
		title: {
			type: String,
			required: true
		}
	}
};
</script>
