<template>
	<div class="verify-banking-card">
		<BaseCard automation-id="verifyInfo" :title="$t('label.banking')">
			<BaseDisplayGroup automation-id="account" :label="$t('label.account')">
				{{ maskedBanking }}
			</BaseDisplayGroup>
			<BaseButton
				class="p-0"
				automation-id="verify-banking-edit"
				variant="link"
				:label="$t('button.label')"
				@click="editClick"
			/>
		</BaseCard>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseButton from '@/components/common/base/BaseButton.vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseDisplayGroup from '@/components/common/base/BaseDisplayGroup.vue';
import { DIRECT_DEPOSIT } from '@/constants/Routes.js';

@Component({
	name: 'VerifyBankingCard',
	components: {
		BaseCard,
		BaseDisplayGroup,
		BaseButton
	},
	mixins: [IdMixin],
	props: {
		maskedBanking: {
			type: String,
			default: null
		}
	}
})
export default class VerifyBankingCard extends Vue {
	get navigationRoute() {
		return DIRECT_DEPOSIT;
	}

	editClick() {
		this.$emit('editClick', [this.navigationRoute, this.$t('navModal.item')]);
	}
}
</script>

<style lang="scss" scoped>
.verify-banking-card ::v-deep .display-group {
	margin-top: 0px;
}
.verify-banking-card ::v-deep .card-title {
	margin-bottom: 8px;
}
</style>

<i18n>
{
  "en": {
    "label": {
        "account": "Account",
        "banking": "Bank account for direct deposit"
    },
	"navModal": {
		"item": "direct deposit information"
	},
	"button": {
        "label": "Edit"
	}
  },
  "fr": {
    "label": {
         "account": "Compte",
         "banking": "Numéro de compte pour le dépôt direct"
    },
	"navModal": {
		"item": "dépôt direct"
	},
	"button": {
        "label": "Modifier"
	}
  }
}
</i18n>
