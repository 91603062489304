<template>
	<SubmitClaimTemplate :percent-complete="71" :step-title="$t('title.step')">
		<BaseForm v-slot="{ invalid }" automation-id="useHsaForm'" class="use-hsa-form">
			<BaseCard>
				<YesNoToggle
					:value="useHsa"
					:label="$t('label.text')"
					:is-required="true"
					:required-error-msg="$t('error.required')"
					automation-id="useHsaScreenToggle"
					@input="updateToggle"
				/>
				<BaseAlert v-if="showReminder && useHsa" class="use-hsa-alert" variant="warning" alternate>
					{{ $t('label.friendlyReminder') }}
					<template #description>{{ $t('label.submitCobFirst') }}</template>
				</BaseAlert>
			</BaseCard>

			<WizardButtonBar
				:disable-next="invalid"
				@navigate-back="navigateBack()"
				@navigate-next="navigateNext()"
			></WizardButtonBar>
		</BaseForm>
	</SubmitClaimTemplate>
</template>

<script>
import Page from '@/models/claims/Page';
import BaseForm from '@/components/common/base/BaseForm';
import BaseCard from '@/components/common/card/BaseCard';
import BaseAlert from '@/components/common/alert/BaseAlert';
import YesNoToggle from '@/components/common/YesNoToggle';
import SubmitClaimTemplate from '@/pages/submit-claim/SubmitClaimTemplate.vue';
import WizardButtonBar from '@/components/wizard/WizardButtonBar.vue';
import { SUBMIT_CLAIM } from '@/constants/Routes.js';
import COB from '@/models/claims/CoordinationOfBenefits';

export default {
	name: 'UseYourHsa',
	components: {
		BaseForm,
		BaseCard,
		BaseAlert,
		YesNoToggle,
		SubmitClaimTemplate,
		WizardButtonBar
	},
	data() {
		return {
			pageName: 'useYourHSA',
			page: {},
			useHsa: null,
			claim: {},
			showReminder: false
		};
	},
	async created() {
		await this.getOrCreatePage();

		if (this.claim) {
			let dateOfService = this.claim?.benefitServices[0]?.dateOfService
				? this.claim?.benefitServices[0]?.dateOfService
				: this.claim?.manualClaimServiceItems[0]?.dateOfService;

			const cobOnFileAPI = await COB.getCOBInfoAPI(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$root.$i18n.locale,
				dateOfService,
				this.claim.lob,
				this.claim.participantId
			);
			if (cobOnFileAPI) {
				this.showReminder = true;
			}
			if (this.claim.isHsa !== null) {
				this.useHsa = this.claim.isHsa;
			}
		}
	},

	methods: {
		updateToggle(useHsa) {
			this.useHsa = useHsa;
		},
		async navigateBack() {
			Page.deleteById(this.pageName);
		},
		async navigateNext() {
			this.useHsa ? (this.claim.isHsa = true) : (this.claim.isHsa = false);
			await Page.updatePage(this.page, this.claim);
			this.$router.push({ name: SUBMIT_CLAIM.ATTACH_DOCUMENTS });
		},
		/**
		 * This method will check to see if a page object already exists for this screen, if it does it will retrieve it and update the claim object with the value it has for the claims.
		 * If no page exists it will create a new one.
		 */
		async getOrCreatePage() {
			this.page = await Page.getPageById(this.pageName);
			if (this.page === null) {
				// Created a structured clone of the claim object from the last page. This ensures that the claim object is not a reference object.
				this.claim = structuredClone(await Page.getClaimFromLastPage());
				this.page = await Page.createNewPage(this.pageName, sessionStorage.getItem('email'));
			} else {
				this.claim = this.page?.claims[0];
			}
		}
	}
};
</script>

<style lang="scss" scoped>
::v-deep .card-body {
	padding-bottom: 15px;
}
.use-hsa-form {
	width: 90%;
	@media (max-width: 767px) {
		width: 100%;
	}
}
.use-hsa-alert {
	margin-top: 1.5rem;
}
.alert-alternate {
	padding-left: 20px;
}
</style>

<i18n>
{
  "en": {
    "title": {
      "step": "Use HSA?"
    },
    "label": {
      "text": "Would you like to use your HSA for any unpaid portion of your claim?",
	  "friendlyReminder": "Reminder:",
	  "submitCobFirst": "You'll want to submit your claim to your other provider first before using your HSA funds. This will get you the greatest reimbursement and prevent you from prematurely using up your HSA funds."
    }, 
	"error": {
		"required": "Please select an option to continue."
	}
  },
  "fr": {
    "title": {
      "step": "Utiliser CGS?"
    },
    "label": {
      "text": "Souhaitez-vous utiliser votre CGS pour couvrir la partie impayée de votre demande de règlement ?",
	  "friendlyReminder": "Rappel : ",
	  "submitCobFirst": "vous pouvez soumettre votre demande de règlement à votre autre assureur avant d’utiliser vos fonds du CGS. De cette façon, vous aurez un plus grand remboursement et vous n’épuiserez pas vos fonds du CGS trop rapidement. "
    },
	"error": {
		"required": "Veuillez sélectionner une option pour continuer."
	}
  }
}
</i18n>
