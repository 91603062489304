<!--
  Navigation Modal

  Example how to use:
		<NavigationModal 
			:errorTitle="$t('error.title')"
			:errorText="$t('error.text')"
			:buttonQuestion="$t('button.question')"
            :modalButtonLabel="$t('button.modalButton')"
			:firstbuttonLabel="$t('button.labelOne')"
			:secondButtonLabel="$t('button.labelTwo')"
			:navigationRoute="Routes.Navigation"
		/>
  -->
<template>
	<ErrorModal
		:id="getUniqueId('navigation')"
		ref="errorModal"
		class="error-modal"
		:error-title="errorTitle"
		:error-message="errorText"
		:modal-show="showModal"
		:is-static="true"
		:has-close-button="false"
		:automation-id="getAutomationId('navigation-modal')"
		@change="closeModal"
	>
		<slot>
			<p class="bold-text">{{ buttonQuestion }}</p>
			<BaseButton
				variant="primary"
				:label="firstButtonLabel"
				pill
				is-right
				type="submit"
				:automation-id="getAutomationId('navigation-no')"
				data-tracking-id="registration-form-submit-button"
				@click="closeModal"
			></BaseButton>
			<BaseButton
				variant="secondary"
				class="second-button"
				:label="secondButtonLabel"
				pill
				is-right
				type="submit"
				:automation-id="getAutomationId('navigation-yes')"
				data-tracking-id="registration-form-submit-button"
				@click="navigate"
			></BaseButton>
		</slot>
	</ErrorModal>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import ErrorModal from '@/components/common/ErrorModal';
import BaseButton from '@/components/common/base/BaseButton';

// @vue/component
@Component({
	name: 'NavigationModal',
	components: {
		ErrorModal,
		BaseButton
	},
	mixins: [IdMixin],
	props: {
		// Message that will appear right below the error icon.
		errorTitle: {
			type: String,
			default: null
		},
		// Message that will appear directly below the error title,
		// in a lighter font.
		errorText: {
			type: String,
			default: null
		},
		// Text that will be displayed above the buttons, usally asking a question.
		buttonQuestion: {
			type: String,
			default: null
		},
		// Text that is displayed on the button that toggles the modal.
		modalButtonLabel: {
			type: String,
			default: null
		},
		// Text displayed on the first button.
		firstButtonLabel: {
			type: String,
			default: null
		},
		// Text displayed on the second button.
		// This button is the one that navigates away from the screen.
		secondButtonLabel: {
			type: String,
			default: null
		},
		// This is the page the modal will navigate too once the member clicks the second button.
		navigationRoute: {
			type: String,
			default: null
		},
		showModal: {
			type: Boolean,
			default: false
		}
	}
})
export default class NavigationModal extends Vue {
	closeModal() {
		this.$emit('closeModal');
	}
	navigate() {
		this.$router.push({ name: this.navigationRoute });
	}
}
</script>
<style lang="scss" scoped>
.bold-text {
	font-family: $font-family-regular;
	font-weight: 400;
}
.second-button {
	margin-left: 10px;
	@media (max-width: 576px) {
		margin-left: 0px;
		margin-top: 10px;
	}
}
.edit-button {
	padding: 0px;
}
.error-modal ::v-deep .modal-content {
	width: 100%;
}
</style>
