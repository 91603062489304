<template>
	<div class="service-list-item">
		<BRow>
			<BCol>
				<p class="service-date mb-1">{{ formattedServiceDate }}</p>
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<p class="mb-1">
					<span id="member-name" class="name">{{ member.fullName }}</span>
					<!-- eslint-disable-next-line -->
			        <span id="member-dob" class="member-dob">({{ member.dob }})</span> 
				</p>
			</BCol>
		</BRow>

		<BRow v-if="value">
			<BCol cols="8" sm="9" md="9" lg="9"
				><p class="text">{{ value.description }}</p></BCol
			>
		</BRow>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
// Mixins
import IdMixin from '@/mixins/id';
// Utils
import { formatDateForUi } from '@/utils/date';

// Components
import { BRow, BCol } from 'bootstrap-vue';

// Models

@Component({
	name: 'PwaServiceListItem',
	mixins: [IdMixin],
	components: {
		BRow,
		BCol
	},
	props: {
		value: {
			type: Object,
			default: () => {}
		},
		member: {
			type: Object,
			default: () => {}
		}
	}
})
export default class PwaServiceListItem extends Vue {
	// ---------------------- Computed Props -------------------------
	get formattedServiceDate() {
		if (this.value?.dateOfService) {
			this.value.dateOfService = this.value?.dateOfService?.replaceAll('-', '');
			return formatDateForUi(this.value.dateOfService, this.$store.state.i18n.locale);
		} else {
			return null;
		}
	}
}
</script>
<style lang="scss" scoped>
.service-list-item {
	padding: 30px;
}
.service-date {
	font-family: $josefin-font-family;
	font-size: 17px;
	font-weight: 500;
}
div,
p {
	font-weight: 300;
}
.name {
	font-weight: 400;
	font-size: 18px;
	line-height: 18px;
}
.member-dob {
	font-weight: 300;
	font-size: 18px;
	margin-left: 0.5em;
}
</style>
