<template>
	<SubmitClaimTemplate :percent-complete="85" :step-title="$t('title.step')">
		<BaseForm v-slot="{ invalid }" automation-id="useHsaForm'" class="use-hsa-form">
			<BaseCard class="comment-card">
				<YesNoToggle
					:value="hasComments"
					:label="$t('label.toggle')"
					:is-required="true"
					automation-id="comments-toggle"
					@input="updateToggle"
				/>
			</BaseCard>
			<BaseCard v-if="hasComments" class="comment-card">
				<label class="comment-label" for="comment-textarea">{{ $t('label.commentTitle') }}</label>
				<p class="comment-example-text">{{ $t('label.commentText') }}</p>
				<BaseTextArea
					id="comment-textarea"
					:value="comments"
					:show-label="false"
					:rows="5"
					maxlength="500"
					:validation-rules="{ required: true }"
					:validation-messages="{ required: $t('error.textareaRequired') }"
					:validation-mode="'aggressive'"
					automation-id="comments"
					@input="updateComments"
				/>
			</BaseCard>
			<WizardButtonBar
				:disable-next="invalid"
				@navigate-back="navigateBack()"
				@navigate-next="navigateNext()"
			></WizardButtonBar>
		</BaseForm>
	</SubmitClaimTemplate>
</template>

<script>
import Page from '@/models/claims/Page';
import BaseForm from '@/components/common/base/BaseForm';
import BaseCard from '@/components/common/card/BaseCard';
import BaseTextArea from '@/components/common/base/BaseTextArea.vue';
import YesNoToggle from '@/components/common/YesNoToggle';
import SubmitClaimTemplate from '@/pages/submit-claim/SubmitClaimTemplate.vue';
import WizardButtonBar from '@/components/wizard/WizardButtonBar.vue';
import { SUBMIT_CLAIM } from '@/constants/Routes.js';

export default {
	name: 'CommentsPage',
	components: {
		BaseForm,
		BaseCard,
		BaseTextArea,
		YesNoToggle,
		SubmitClaimTemplate,
		WizardButtonBar
	},
	data() {
		return {
			pageName: 'comments',
			page: {},
			hasComments: null,
			comments: null,
			claim: {}
		};
	},
	async created() {
		await this.getOrCreatePage();

		this.hasComments = this.claim?.hasComments;
		this.comments = this.claim?.comments;
	},

	methods: {
		updateToggle(value) {
			this.hasComments = value;
			// If member selects no then clear comments.
			if (!value) {
				this.comments = '';
			}
		},
		updateComments(value) {
			this.comments = value;
		},
		async navigateBack() {
			Page.deleteById(this.pageName);
		},
		async navigateNext() {
			this.claim.hasComments = this.hasComments;
			this.claim.comments = this.comments;
			await Page.updatePage(this.page, this.claim);
			this.$router.push({ name: SUBMIT_CLAIM.CLAIM_SUMMARY });
		},
		/**
		 * This method will check to see if a page object already exists for this screen, if it does it will retrieve it and update the claim object with the value it has for the claims.
		 * If no page exists it will create a new one.
		 */
		async getOrCreatePage() {
			this.page = await Page.getPageById(this.pageName);
			if (this.page === null) {
				// Created a structured clone of the claim object from the last page. This ensures that the claim object is not a reference object.
				this.claim = structuredClone(await Page.getClaimFromLastPage());
				this.page = await Page.createNewPage(this.pageName, sessionStorage.getItem('email'));
			} else {
				this.claim = this.page?.claims[0];
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.comment-card {
	width: 70%;
	@media (max-width: 767px) {
		width: 100%;
	}
}
.comment-card .validation-provider {
	margin-bottom: 0;
}
.comment-label {
	font-family: $font-family-headline;
	font-size: 18px;
	width: 99%;
}
.comment-example-text {
	font-family: $font-family-regular;
	font-size: 15px;
	font-weight: 300;
	margin-bottom: 10px;
	line-height: 1.6em;
	width: 90%;
	@media (max-width: 767px) {
		width: 100%;
	}
}
</style>

<i18n>
{
  "en": {
        "title": {
            "step": "Comments"
        },
        "label": {
            "toggle": "Would you like to add any comments to your claim?",
            "commentTitle": "Please share anything else you think could help us process your claim.",
            "commentText": "For example, you can note which plan member received what item on a receipt. We cannot reply to comments or questions submitted through this form."
        },
        "error": {
            "textareaRequired": "Please enter a comment, or select \"No\" above."
        }
  },
  "fr": {
        "title": {
            "step": "Commentaires"
        },
        "label": {
            "toggle": "Voulez-vous ajouter des commentaires à votre demande de règlement?",
            "commentTitle": "Veuillez inscrire toute autre information qui pourrait nous aider à traiter votre demande de règlement.",
            "commentText": "Par exemple, vous pourriez indiquer quelles personnes assurées ont reçu les articles sur un reçu. À noter que nous ne pouvons pas répondre aux commentaires ou aux questions soumis dans ce formulaire."
        },
        "error": {
            "textareaRequired": "Veuillez entrer un commentaire ou sélectionner « Non » ci-dessus."
        }
  }
}
</i18n>
