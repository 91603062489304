<template>
	<span>{{ getDate(date) }}</span>
</template>

<script>
/**
 * This component displays the date in 'day month YYYY' format.
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import { formatDateForUi } from '@/utils/date';

@Component({
	name: 'DisplayDate',
	props: {
		date: {
			type: String,
			default: null
		}
	}
})
export default class DisplayDate extends Vue {
	getDate(date) {
		if (date) {
			return formatDateForUi(date, this.$store.state.i18n.locale);
		}
	}
}
</script>
