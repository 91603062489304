<!--
	Wizard Button Bar

  Displays a Bootstrap Button Toolbar.  It contains an optional "Back" button 
  and a mandatory "Next" button.

  The buttons do not perform the navigation.  They emit events ("actions up") to
  the parent component.
-->

<template>
	<div class="mb-4">
		<BButtonToolbar
			:id="getUniqueId('wizardButtonbar')"
			:aria-label="$t('aria.toolbarLabel')"
			:data-test-automation-id="getAutomationId('wizardButtonbar')"
			:data-tracking-id="getAutomationId('wizardButtonbar')"
		>
			<BaseButton
				v-if="showBackButton"
				:id="getUniqueId('backButton')"
				class="pl-0 mr-3"
				:automation-id="getAutomationId('backButton')"
				:label="backButtonLabelLocalized"
				:aria-label="backButtonLabelLocalized"
				:icon="['fal', 'chevron-left']"
				icon-position="left"
				variant="link"
				@click="navigateBack()"
			></BaseButton>
			<BaseButton
				v-if="showNextButton"
				:id="getUniqueId('nextButton')"
				:automation-id="getAutomationId('nextButton')"
				:label="nextButtonLabelLocalized"
				:aria-label="nextButtonLabelLocalized"
				:icon="nextButtonIcon"
				:variant="nextButtonVariant"
				:disabled="disableNext"
				@click="navigateNext()"
			></BaseButton>
		</BButtonToolbar>
	</div>
</template>

<script>
import IdMixin from '@/mixins/id';
import BaseButton from '@/components/common/base/BaseButton.vue';
import { BButtonToolbar } from 'bootstrap-vue';

export default {
	name: 'WizardButtonBar',
	components: {
		BaseButton,
		BButtonToolbar
	},
	mixins: [IdMixin],
	props: {
		showBackButton: {
			type: Boolean,
			default: true,
			required: false
		},
		showNextButton: {
			type: Boolean,
			default: true,
			required: false
		},
		backButtonLabel: {
			type: String,
			default: null,
			required: false
		},
		nextButtonLabel: {
			type: String,
			default: null,
			required: false
		},
		disableNext: {
			type: Boolean,
			default: false,
			required: false
		},
		nextButtonIcon: {
			type: Array,
			default: () => ['fal', 'chevron-right']
		},
		// Automatically navigate back. instead of just emitting the navigate-back event.
		autoBack: {
			type: Boolean,
			default: true
		},
		nextButtonVariant: {
			type: String,
			default: 'primary'
		}
	},

	computed: {
		nextButtonLabelLocalized() {
			return this.nextButtonLabel || this.$t('button.next');
		},
		backButtonLabelLocalized() {
			return this.backButtonLabel || this.$t('button.back');
		}
	},

	methods: {
		navigateBack() {
			this.$emit('navigate-back');
			// Check if the autoBack prop is set to true. If it is then navigate back using this component.
			if (this.autoBack) {
				if (window.location.hash) {
					this.$router.go(-2);
				} else {
					this.$router.back();
				}
			}
		},

		navigateNext() {
			this.$emit('navigate-next');
		}
	}
};
</script>

<i18n>
{
  "en": {
    "button": {
      "next": "Next",
      "back": "Back"
    },
    "aria": {
      "toolbarLabel": "Navigation Toolbar"
    }
  },
  "fr": {
    "button": {
      "next": "Suivant",
      "back": "Précédent"
    },
    "aria": {
      "toolbarLabel": "Barre de navigation"
    }
  }
}
</i18n>
