<!--
 * This component shown when the user have hsa funds
 * Further it checks if the account has any eligible claims. Claim not found modal is shown if there is no eligible claim.
 -->
<template>
	<BaseCollapse
		:button-label="$t('button.searchCollapse')"
		button-variant="primary"
		:icon-two="['fal', 'search']"
		:visible="isVisible"
		class="claim-history-collapse"
		automation-id="claimHistoryPage"
		@input="changeIsVisible"
	>
		<BaseForm
			ref="premium-payments-search-date"
			v-slot="{ invalid }"
			:automation-id="getAutomationId('paymentDateSearchForm')"
			class="padding"
			@keydown.enter="onSubmit"
			@submit="onSubmit"
		>
			<BRow class="input-row">
				<BCol v-if="participants.length > 1" cols="12" md="12" lg="5">
					<label class="participant-label" for="participant-dropdown">{{
						$t('label.participant')
					}}</label
					><br />
					<ParticipantDropdown
						id="participant-dropdown"
						class="participant-dropdown flex-grow-1"
						include-all
						:participants="participants"
						:selected-participant="selectedParticipant"
						:all-label="$t('label.all')"
						variant="secondary"
						@click="updateSelectedParticipant"
					/>
				</BCol>
				<BCol v-else cols="12" md="12" lg="5">
					<label class="participant-label" for="participant">{{ $t('label.participant') }}</label>
					<p id="participant" class="participant-name">
						{{ participants[0].firstName }} {{ participants[0].lastName }}
					</p>
				</BCol>
				<BCol cols="12" md="12" lg="5">
					<BaseSelect
						:value="benefit"
						:is-plain="true"
						class="benefit-dropdown"
						:label="$t('label.benefit')"
						:validation-rules="{ required: true }"
						:options="benefits"
						:automation-id="getAutomationId('province')"
						@change="onChangeBenefit"
					/>
				</BCol>
				<BCol class="search-button-column" cols="12" md="12" lg="2">
					<BaseButton
						class="search-button m-0"
						variant="primary"
						:disabled="invalid"
						:label="$t('button.search')"
						is-right
						pill
						:automation-id="getAutomationId('searchByDate')"
						type="submit"
					/>
				</BCol>
			</BRow>
		</BaseForm>
	</BaseCollapse>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
``;
import { BRow, BCol } from 'bootstrap-vue';
// components
import BaseButton from '@/components/common/base/BaseButton';
import BaseForm from '@/components/common/base/BaseForm';
import BaseCollapse from '@/components/common/base/BaseCollapse';
import BaseSelect from '@/components/common/base/BaseSelect';
import ParticipantDropdown from '@/components/common/ParticipantDropdown.vue';
// constants

@Component({
	name: 'HealthSpendingAccountPage',
	components: {
		BRow,
		BCol,
		BaseButton,
		BaseCollapse,
		BaseSelect,
		BaseForm,
		ParticipantDropdown
	},
	mixins: [IdMixin, BreadcrumbsManager],
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		participants: {
			type: Array,
			default: null
		}
	}
})
export default class HealthSpendingAccountSearchFields extends Vue {
	selectedParticipant = { participantId: 'all' };
	benefit = 'all';
	isVisible = true;
	searchCriteria = null;

	// Get the list of benefits. Travel is not included in HSA.
	get benefits() {
		return [
			{ value: 'all', text: this.$t('benefit.all') },
			{ value: '05', text: this.$t('benefit.dental') },
			{ value: '04', text: this.$t('benefit.drug') },
			{ value: '03', text: this.$t('benefit.eob') },
			{ value: '01', text: this.$t('benefit.hospital') }
		];
	}

	// Update the participant.
	updateSelectedParticipant(value) {
		this.selectedParticipant = value.participant;
	}

	// Update the benefit/claim type
	onChangeBenefit(value) {
		this.benefit = value;
	}

	// Show or hide the base collapse
	changeIsVisible() {
		this.isVisible = !this.isVisible;
	}

	// Save the selected participant and benefit to an object to be passed to parent component.
	onSubmit() {
		this.searchCriteria = {
			participantId: this.selectedParticipant.participantId,
			benefit: this.benefit
		};
		this.$emit('search', this.searchCriteria);
	}
}
</script>

<style lang="scss" scoped>
.participant-label {
	font-family: $font-family-headline;
	font-size: 18px;
}
.participant-dropdown {
	min-width: min(390px, 100%);
	min-height: 52px;
	width: 100%;
	@media (max-width: 997px) {
		margin-bottom: 1em;
	}
}
.search-button-column {
	margin: auto;
}
.input-row {
	padding: 30px 30px 15px 30px;
}
.benefit-dropdown ::v-deep(label) {
	margin-bottom: 8px;
}
.benefit-dropdown ::v-deep(select.form-control) {
	height: 52px;
}
</style>
<i18n>
{
    "en": {
        "button": {
            "searchCollapse": "Search your claims",
            "search": "Search"
        },
        "label": {
            "participant": "Who is it for?",
            "benefit": "Benefit category",
            "all": "All"
        },
		"benefit": {
            "hospital": "Hospital",
            "eob": "Extended Health Benefits",
            "drug": "Drug",
            "dental": "Dental",
            "all": "All"
        }
       
    },
    "fr": {
        "button": {
            "searchCollapse": "Recherchez dans vos demandes de règlement",
            "search": "Rechercher"
        },
        "label": {
            "participant": "Pour qui est-ce?",
            "benefit": "Catégorie de garantie",
            "all": "Tous"
        },
		"benefit": {
            "hospital": "Hôpital",
            "eob": "Soins complémentaires",
            "drug": "Médicaments",
            "dental": "Dentaire",
			"all": "Toutes"
        }
    }
}
</i18n>
