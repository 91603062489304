<template>
	<div class="service-list-item">
		<BRow>
			<BCol>
				<p class="service-date text">{{ formattedServiceDate }}</p>
			</BCol>
		</BRow>
		<BRow>
			<BCol cols="8" sm="9" md="9" lg="9"
				><p class="text">{{ description }}</p></BCol
			>
			<BCol cols="4" sm="3" md="3" lg="3"
				><p class="currency text">{{ formattedAmountPaid }}</p></BCol
			>
		</BRow>
		<BRow v-if="!hideButtons">
			<BaseButton
				:label="$t('button.edit')"
				:aria-label="$t('button.edit')"
				class="button button-edit p-0"
				variant="link"
				:disabled="disableButtons"
				:automation-id="getAutomationId('service-list-edit')"
				@click="editService()"
			/>
			<div v-if="!hideRemove">
				<BaseButton
					:label="$t('button.remove')"
					:aria-label="$t('button.remove')"
					class="button p-0"
					variant="link"
					:disabled="disableButtons"
					:automation-id="getAutomationId('service-list-remove')"
					@click="removeService()"
				/>
			</div>
		</BRow>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
// Mixins
import IdMixin from '@/mixins/id';
// Utils
import { formatDateForUi } from '@/utils/date';
import { parseNumber } from '@/utils/NumberUtil';

// Components
import { BRow, BCol } from 'bootstrap-vue';
import BaseButton from '@/components/common/base/BaseButton.vue';

// Models

@Component({
	name: 'ServiceListItem',
	mixins: [IdMixin],
	components: {
		BaseButton,
		BRow,
		BCol
	},
	props: {
		value: {
			type: Object,
			default: () => {}
		},
		hideButtons: {
			type: Boolean,
			default: false
		},
		// Prop will disable the buttons. Used when a memmber is adding or editing a service.
		disableButtons: {
			type: Boolean,
			default: false
		},
		// Prop used to determine if we hide the remvoe button from the service list item.
		hideRemove: {
			type: Boolean,
			default: false
		}
	}
})
export default class ServiceListItem extends Vue {
	// ---------------------- Computed Props -------------------------

	get description() {
		if (this.value?.description && this.value?.description !== null) {
			return this.value.description;
		} else if (this.value?.specialtyCode && this.value?.specialtyCode !== null) {
			return this.value.specialtyCode;
		} else {
			return null;
		}
	}

	get formattedServiceDate() {
		if (this.value?.dateOfService) {
			this.value.dateOfService = this.value?.dateOfService?.replaceAll('-', '');
			return formatDateForUi(this.value.dateOfService, this.$store.state.i18n.locale);
		} else {
			return null;
		}
	}

	get formattedAmountPaid() {
		if (this.value?.amountPaid) {
			const numericValue = this.parseToNumericValue(this.value.amountPaid);
			const formatter = new Intl.NumberFormat(`${this.$root.$i18n.locale}-CA`, {
				style: 'currency',
				currency: 'CAD',
				minimumFractionDigits: 2
			});

			if (numericValue != null && !isNaN(numericValue)) {
				let valueToreturn = formatter.format(numericValue);
				return this.$root.$i18n.locale === 'fr' ? valueToreturn.replace('CA', '') : valueToreturn;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	// ---------------------- Input Updates -------------------------

	editService() {
		this.$emit('edit');
	}

	saveService(service) {
		this.$emit('save', service);
	}

	removeService() {
		this.$emit('remove');
	}

	// ---------------------- UTILS -------------------------

	parseToNumericValue(value) {
		return value != null && ('' + value).trim().length > 0
			? parseNumber(value, this.$root.$i18n.locale)
			: null;
	}
}
</script>
<style lang="scss" scoped>
.service-list-item {
	padding: 30px;
}
.service-date {
	font-family: $josefin-font-family;
	font-size: 17px;
	font-weight: 500;
}
.currency {
	font-weight: 400;
}
.button {
	font-family: $lato-font-family;
	font-weight: 400;
	text-decoration: underline;
}
.text {
	margin-bottom: 0.5rem;
}
.button-edit {
	margin-left: 15px;
	margin-right: 15px;
}
</style>
<i18n>
{
	"en": {
		"button": {
            "edit": "Edit",
            "remove": "Remove"
        }
	},
	"fr": {
		"button": {
            "edit": "Modifier",
            "remove": "Supprimer"
        }
	}
}
</i18n>
