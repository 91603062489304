<!--
   This component is responsible to render HSA claim results.
-->
<template>
	<div>
		<BRow class="name-header pt-2 pl-2 m-0">
			<BCol sm="12" md="6" class="header"
				>{{ participantName }}{{ ` - ${participants.length}` }}
				<span v-if="participants.length > 0">{{ $t('table.claims') }} </span>
				<span v-else>{{ $t('table.claim') }}</span>
			</BCol>
			<BCol sm="12" md="6" class="dob text-md-right d-flex justify-content-md-end"
				>{{ $t('label.dateOfBirth') }} <DisplayDate class="display-dob" :date="dob" />
			</BCol>
		</BRow>
		<BTable
			:items="participants"
			:fields="getFields()"
			sort-by="serviceDate"
			:sort-desc="true"
			class="claim-result-table"
			stacked="sm"
			striped
		>
			<template #cell(lob)="{ item }">
				{{ getClaimType(item.lob) }}
			</template>
			<template #cell(serviceDate)="{ item }">
				<DisplayDate :date="item.serviceDate" />
			</template>
			<template #cell(paidAmount)="{ item }">
				<DisplayCurrency :currency="item.paidAmount" />
			</template>
			<template #cell(calculatedEligibleAmount)="{ item }">
				<DisplayCurrency :currency="item.calculatedEligibleAmount" />
			</template>
			<template #cell(selectClaim)="{ item }">
				<BaseButton
					class="select-claim-btn px-0"
					:label="$t('label.selectClaim')"
					:aria-label="$t('label.selectClaim')"
					:icon="['fal', 'chevron-right']"
					icon-position="right"
					:pill="false"
					icon-size="xs"
					variant="link"
					:automation-id="'selectClaim'"
					@click="selectClaim(item)"
				/>
			</template>
			<template #custom-foot>
				<BTr class="footer">
					<BTh role="cell">{{ $t('label.total') }}</BTh>
					<BTh role="cell"> </BTh>
					<BTh role="cell"> <DisplayCurrency :currency="toalAmountReimbursed" /></BTh>
					<BTh role="cell"><DisplayCurrency :currency="totalAmountEligibleHsa" /></BTh>
					<BTh role="cell"> </BTh>
				</BTr>
			</template>
			<template #table-caption></template>
		</BTable>
		<BTable
			thead-class="d-md-none head"
			:items="getFooterData"
			:fields="[
				{ key: 'total', label: $t('label.total') },
				{ key: 'paidAmount', label: $t('table.amountReimbursed') },
				{ key: 'calculatedEligibleAmount', label: $t('table.amountHSA') }
			]"
			stacked="sm"
			:borderless="true"
			class="claim-result-table-stacked"
		>
			<template #cell(paidAmount)="{ item }">
				<DisplayCurrency :currency="item.paidAmount" />
			</template>
			<template #cell(calculatedEligibleAmount)="{ item }">
				<DisplayCurrency :currency="item.calculatedEligibleAmount" />
			</template>
		</BTable>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol, BTable, BTr, BTd, BTh } from 'bootstrap-vue';
// components
import BaseButton from '@/components/common/base/BaseButton';
import DisplayCurrency from '@/components/common/DisplayCurrency';
import DisplayDate from '@/components/common/DisplayDate';
// models
import Claims from '@/models/claims/Claims';

@Component({
	name: 'ClaimSearchResult',
	components: {
		BRow,
		BCol,
		BaseButton,
		BTable,
		BTr,
		BTd,
		BTh,
		DisplayCurrency,
		DisplayDate
	},
	props: {
		participantInfo: {
			type: Array,
			default: null
		},
		participantName: {
			type: String,
			default: ''
		},
		dob: {
			type: String,
			default: ''
		},
		participants: {
			type: Array,
			default: null
		},
		columns: {
			type: Array,
			default: null
		},
		lobCodes: {
			type: Array,
			default: null
		},
		participantId: {
			type: String,
			default: ''
		}
	}
})
export default class HealthSpendingAccountClaimSearchResult extends Vue {
	formattedMonth = null;
	claimType = null;
	rowData = null;
	claim = {};
	toalAmountReimbursed = 0;
	totalAmountEligibleHsa = 0;
	getFooterData() {
		let bottomContent = [
			{
				total: this.$t('label.total'),
				paidAmount: this.getTotalAmountReimbursed(),
				calculatedEligibleAmount: this.getTotalAmountEligibleHsa()
			}
		];
		return bottomContent;
	}
	getTotalAmountReimbursed() {
		let total = 0;
		Object.values(this.participants).forEach((value) => {
			total = value.paidAmount + total;
		});
		this.toalAmountReimbursed = total;
		return total;
	}
	getTotalAmountEligibleHsa() {
		let total = 0;
		Object.values(this.participants).forEach((value) => {
			total = value.calculatedEligibleAmount + total;
		});
		this.totalAmountEligibleHsa = total;
		return total;
	}
	getClaimType(lob) {
		let claimType = null;
		this.lobCodes?.map((item) => {
			if (lob === item.code) {
				if (this.$root.$i18n.locale === 'en') {
					claimType = item.label_en;
				} else {
					claimType = item.label_fr;
				}
			}
		});
		return claimType;
	}
	getFields() {
		return [
			{
				key: 'lob',
				label: this.$t('table.claimType')
			},
			{
				key: 'serviceDate',
				label: this.$t('table.serviceDate')
			},
			{
				key: 'paidAmount',
				label: this.$t('table.amountReimbursed')
			},
			{
				key: 'calculatedEligibleAmount',
				label: this.$t('table.amountHSA')
			},
			{
				key: 'selectClaim',
				label: this.$t('label.selectClaim')
			}
		];
	}

	async selectClaim(item) {
		this.$emit('selectClaim', item);
	}
	get locale() {
		return this.$root.$i18n ? this.$root.$i18n.locale : 'en';
	}
	async created() {
		await this.getClaim();
	}
	async getClaim() {
		this.claim = await Claims.getClaimByEmail(sessionStorage.getItem('email'));
	}
}
</script>
<style lang="scss" scoped>
.select-claim-btn {
	font-family: $lato-font-family;
	font-size: 15px;
	margin-top: -7px;
}
.claim-result-table-stacked {
	margin-top: -17px;
	@media (min-width: 576px) {
		display: none;
	}
}
.name-header {
	background-color: $primary;
	border-radius: 10px 10px 0px 0px;
	height: 100%;
	color: $white;
}
.header {
	font-size: 21px;
	font-family: $josefin-font-family;
	padding-top: 5px;
	padding-bottom: 10px;
	@media (max-width: 767px) {
		padding-bottom: 0px;
	}
}
.dob {
	font-size: 17px;
	font-family: $lato-font-family;
	padding-top: 5px;
	@media (max-width: 767px) {
		padding-top: 0px;
		padding-bottom: 10px;
	}
}
.display-dob {
	padding-left: 5px;
}
.claim-result-table.table.b-table.b-table-stacked-sm {
	@media (max-width: 576px) {
		margin-top: -25px;
	}
}
.table.b-table.b-table-stacked-sm ::v-deep tbody tr [data-label]::before {
	font-family: $josefin-font-family;
	font-weight: 400;
	width: 59%;
}
.table.b-table.b-table-stacked-sm ::v-deep tbody tr [data-label='Select Claim']::before,
.table.b-table.b-table-stacked-sm ::v-deep tbody tr [data-label='Sélectionner la demande']::before {
	display: none;
}
.table {
	& ::v-deep td {
		height: 43px;
		padding-top: 10px;
		padding-bottom: 0px;
		@media (max-width: 576px) {
			height: initial;
		}
	}
	& tfoot tr {
		border: none;
	}
	& ::v-deep tr {
		font-family: $lato-font-family;
		font-size: 15px;
		border-left: 1px solid $gray-light;
		border-right: 1px solid $gray-light;
	}
	& ::v-deep tr:nth-child(odd) {
		background: $white;
	}
	.b-table-stacked-sm ::v-deep tr:first-child {
		background: $white;
	}
}
.table ::v-deep th:last-child div {
	display: none;
}
.claim-result-table-stacked.b-table-stacked-sm {
	& ::v-deep tr:first-child {
		background-color: #e4e4e4;
		height: 150px;
		border-radius: 0px 0px 5px 5px;
		border: none;
	}
	&::v-deep tbody tr [data-label] div {
		font-weight: bold;
	}
	&::v-deep tbody tr [data-label='Total'] div {
		display: none;
	}
	&:last-child {
		border-radius: 0 5px 0 0;
	}
	&:not(:first-child) {
		border-left: none;
	}
	& ::v-deep td:first-child::before {
		font-size: 18px;
	}
}
.table ::v-deep tr:nth-child(even):not(:first-child):not(:last-child) {
	background: $gray-very-light;
}
.table ::v-deep td:nth-child(4),
.table ::v-deep td:nth-child(3) {
	@media (min-width: 820px) {
		text-align: right;
	}
}
.table ::v-deep td:last-child {
	@media (min-width: 820px) {
		text-align: center;
	}
}
.table tfoot th {
	background: $light-grey;
	font-size: 18px;
	font-family: $josefin-font-family;
}
.table ::v-deep th {
	font-family: $josefin-font-family;
	font-size: $body-regular-button-font-size;
	font-weight: $font-regular;
	background: $light-grey;
	&:nth-child(4),
	&:nth-child(3) {
		@media (min-width: 820px) {
			text-align: right;
		}
	}
}
.table tfoot {
	& th:first-child {
		border-radius: 0px 0px 0px 5px;
	}
	& th:last-child {
		border-radius: 0px 0px 5px 0px;
	}
}
.table.b-table.b-table-stacked-sm ::v-deep tbody tr [data-label] div {
	width: auto;
}
.claim-result-table.b-table.b-table-stacked-sm ::v-deep tbody tr td:last-child {
	text-align: center;
}
</style>
<i18n>
{
"en": {

    "table": {
      "claim": "Claim",
      "claims": "Claims",
      "claimType": "Claim Type",
      "serviceDate": "Service Date",
      "amountReimbursed": "Amount Reimbursed",
      "amountHSA": "Amount Eligible for HSA"
    },
    "label": {
            "total":"Total",
            "selectClaim": "Select Claim",
            "dateOfBirth": "Date of Birth: "
    }
    },
"fr": {
    "table": {
      "claim": "demande de règlement",
      "claims": "demande(s) de règlement",
      "claimType": "Type de demande de règlement",
      "serviceDate": "Date de service",
      "amountReimbursed": "Montant remboursé",
      "amountHSA": "Montant admissible au CGS"
    },
    "label": {
            "total":"Total",
            "selectClaim": "Sélectionner la demande",
            "dateOfBirth": "Date de naissance: "
    }
    }
}
</i18n>
