<template>
	<div class="m-0">
		<h3>{{ $t('title') }}</h3>
		<p :class="showProvider ? 'member-info' : 'mb-0'">
			<span id="member-name" class="name">{{ member.fullName }}</span>
			<!-- eslint-disable-next-line -->
			<span id="member-dob" class="member-dob">({{ member.dob }})</span> 
		</p>
		<div v-if="showProvider">
			<h3>{{ $t('provider') }}</h3>
			<h4 class="mb-0 name">{{ providerFullName }}</h4>
			<p class="provider-type">{{ provider.providerTypeName }}</p>
			<BaseButton
				:id="getUniqueId('provider-details-button')"
				ref="triggerButton"
				:label="$t('details')"
				class="p-0 details-button"
				:pill="false"
				variant="link"
				:automation-id="getAutomationId('provider-details-button')"
				type="button"
				:aria-label="$t('details')"
				@click="toggleModal(true)"
			>
			</BaseButton>
			<div class="provider-modal">
				<BaseModal
					automation-id="claimsStatusInfoModal"
					:modal-show="modalShow"
					css-class="provider-modal"
					:is-static="true"
					size="md"
					:modal-title="providerFullName"
					@change="toggleModal"
				>
					<template #contentBody>
						<p>{{ provider.providerTypeName }}</p>
						<div class="d-block">
							<div class="flex-grow-1 provider-info">
								<div v-if="provider && provider.organizationName && provider.firstName">
									{{ provider.organizationName }}
								</div>
								<div>{{ provider.addressLine1 }}</div>
								<div :v-if="provider.addressLine2">
									{{ provider.addressLine2 }}
								</div>
								<div>{{ provider.city }}, {{ provider.province }} {{ formattedPostal }}</div>
							</div>
							<div>
								{{ formattedNumber }}
							</div>
						</div>
					</template>
				</BaseModal>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { formatPhoneNumberWithParentheses } from '@/utils/commonUtils.js';
import BaseModal from '@/components/common/base/BaseModal';
import BaseButton from '@/components/common/base/BaseButton';

// @vue/component
@Component({
	name: 'ProviderCard',
	components: {
		BaseModal,
		BaseButton
	},
	mixins: [IdMixin],
	props: {
		member: {
			type: Object,
			default: () => {}
		},
		provider: {
			type: Object,
			default: () => {}
		},
		showProvider: {
			type: Boolean,
			default: false
		}
	}
})
export default class ProviderCard extends Vue {
	modalShow = false;

	get formattedPostal() {
		const pCode = this.provider.postalCode;
		return `${pCode.substring(0, 3)} ${pCode.substring(3, 6)}`;
	}
	get formattedNumber() {
		if (this.provider.phoneNumber) {
			return formatPhoneNumberWithParentheses(this.provider.phoneNumber);
		}
		return '';
	}
	get providerFullName() {
		if (!this.provider?.firstName) {
			return this.organizationName;
		}
		return `${this.provider?.firstName} ${this.provider?.lastName}`;
	}

	toggleModal(value) {
		this.modalShow = value;
	}
}
</script>
<style lang="scss" scoped>
div,
p {
	font-weight: 300;
}
.name {
	font-weight: 400;
	font-size: 18px;
	line-height: 18px;
}
.member-dob {
	font-weight: 300;
	font-size: 18px;
	margin-left: 0.5em;
}
.member-info {
	margin-bottom: 30px;
}
.provider-info > div:first-child {
	font-weight: 400;
}
.provider-type {
	margin-bottom: 0.5em;
}
.provider-modal ::v-deep(.modal-body) {
	padding-top: 0px;
}
.provider-modal ::v-deep(.modal-header) {
	margin-left: 32px;
}
.details-button {
	font-family: $font-family-regular;
	font-weight: 500;
	font-size: 15px;
	text-decoration: underline;
}
</style>
<i18n>
{
	"en": {
		"title": "Claiming for",
		"provider": "Provider",
		"details": "Details"
	},
	"fr": {
		"title": "Demande de règlement pour",
		"provider": "Prof. de la santé",
		"details": "Détails"
	}
}
</i18n>
