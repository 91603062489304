<template>
	<div>
		<p v-if="!hideTitle" class="h2">{{ termsAndCondition.title }}</p>
		<div v-for="content in termsAndCondition.content" :key="content.header">
			<p v-if="content.header" class="h4">{{ content.header }}</p>
			<template v-for="paragraph in content.paragraphs">
				<p :key="paragraph.paragraph">{{ paragraph.paragraph }}</p>
			</template>
		</div>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';

// @vue/component
@Component({
	name: 'TermsAndConditionJsonContent',
	components: {},
	mixins: [IdMixin],
	props: {
		automationId: {
			type: String,
			required: true,
			default: ''
		},
		termsAndCondition: {
			type: Object,
			default: null
		},
		hideTitle: {
			type: Boolean,
			default: false
		}
	}
})
export default class TermsAndConditionJsonContent extends Vue {}
</script>

<style lang="scss" scoped>
.h4 {
	margin-bottom: 0px;
}
</style>
