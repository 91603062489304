<!--
 * This component shown when the user have hsa funds
 * Further it checks if the account has any eligible claims. Claim not found modal is shown if there is no eligible claim.
 -->
<template>
	<SubmitClaimTemplate :percent-complete="28" :step-title="$t('title.step')" :is-hsa-screen="true">
		<p class="top-title">{{ $t('text') }}</p>
		<HealthSpendingAccountSearchFields
			:participants="dependents"
			@search="filterHsaEligibleClaims"
		/>
		<div v-if="filteredResults !== null" class="mt-4">
			<div v-for="item in dependents" :key="item.participantId + '_name'">
				<div v-for="(values, key) in filteredResults" :key="key + '_participant'">
					<div v-if="key === item.participantId">
						<ClaimSearchResult
							:participants="values"
							:participant-name="item.firstName + ' ' + item.lastName"
							:dob="item.dateOfBirth"
							:lob-codes="labels"
							:participant-id="item.participantId"
							@selectClaim="
								(arg) => {
									selectClaim(arg, key);
								}
							"
						/>
					</div>
				</div>
			</div>
		</div>
		<div align-h="center" class="pt-3 pb-3 footer-container">
			<BaseButton
				:automation-id="'claimNotFoundBtn'"
				variant="primary"
				:label="$t('button.claimNotFound')"
				:class="isEnglish ? 'claim-not-found-btn' : 'claim-not-found-btn-fr'"
				@click="showClaimNotFoundModal"
			/>
		</div>
		<ClaimNotFound :not-found="showModal" @modal="modal" />
		<WizardButtonBar :show-next-button="false" @navigate-back="navigateBack()"></WizardButtonBar>
		<HsaClaimError :no-claim-modal="hsaClaimModalShow" />
		<HsaFundError :modal-show="hsaModalShow" />
		<ErrorModal
			:data-test-automation-id="getAutomationId('hsa-no-results-modal')"
			:modal-show="showNoResults"
			:error-title="$t('error.noResultsTitle')"
			:error-message="$t('error.noResultsText')"
			@change="showNoResults = false"
		>
			<BaseButton
				:label="$t('error.close')"
				variant="primary"
				automation-id="no-results-error-close"
				@click="showNoResults = false"
			/>
		</ErrorModal>
	</SubmitClaimTemplate>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import { BRow, BCol } from 'bootstrap-vue';
// models
import Page from '@/models/claims/Page';
import COB from '@/models/claims/CoordinationOfBenefits';
import Dependent from '@/models/Dependent.js';
import HsaClaims from '@/models/claims/HsaClaims';
// components
import ClaimSearchResult from '@/components/submit-claim/hsa/HealthSpendingAccountClaimSearchResult';
import HealthSpendingAccountSearchFields from '@/components/submit-claim/hsa/HealthSpendingAccountSearchFields.vue';
import HsaClaimError from './HsaEligibleClaimError.vue';
import ClaimNotFound from './ClaimNotFound.vue';
import BaseButton from '@/components/common/base/BaseButton';
import SubmitClaimTemplate from '@/pages/submit-claim/SubmitClaimTemplate.vue';
import WizardButtonBar from '@/components/wizard/WizardButtonBar.vue';
import HsaFundError from '../hsa/HsaFundError.vue';
import ErrorModal from '@/components/common/ErrorModal';

// constants
import { SUBMIT_CLAIM } from '@/constants/Routes.js';

@Component({
	name: 'HealthSpendingAccountPage',
	components: {
		BRow,
		BCol,
		HsaClaimError,
		HsaFundError,
		ErrorModal,
		ClaimSearchResult,
		BaseButton,
		ClaimNotFound,
		SubmitClaimTemplate,
		WizardButtonBar,
		HealthSpendingAccountSearchFields
	},
	mixins: [IdMixin, BreadcrumbsManager],
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		value: {
			type: String,
			default: ''
		}
	}
})
export default class HealthSpendingAccountPage extends Vue {
	data() {
		return {
			page: {},
			pageName: 'healthSpending',
			showModal: false,
			hsaModalShow: false,
			hsaClaimModalShow: false,
			results: {},
			filteredResults: {},
			dependents: null,
			eligibleHsaClaims: null,
			currentPage: 1,
			labels: null,
			totalRows: 0,
			claims: {},
			hasFunds: null,
			showNoResults: false
		};
	}

	/**
	 * Get the list of dependents.
	 */
	async getParticipantInfo() {
		return Dependent.getDependents(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		).then((response) => {
			this.dependents = response.data;
			return this.dependents.data;
		});
	}

	/**
	 * Check if the member has hsa funds. If they do not then display a modal notifiying them and leave the claim flow.
	 */
	async hasHsaFunds() {
		this.hasFunds = await HsaClaims.hasHsaFunds(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.locale
		);

		if (this.hasFunds) {
			return true;
		} else {
			this.$store.dispatch('isOkToLeaveClaimSubmission', true);
			return false;
		}
	}

	pageChange(page) {
		this.currentPage = page;
	}
	get locale() {
		return this.$root.$i18n ? this.$root.$i18n.locale : 'en';
	}

	async getlabel() {
		this.labels = await HsaClaims.getLobLabels(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.locale
		);
	}

	async created() {
		this.hsaModalShow = !this.hasHsaFunds();
		await this.getlabel();
		await this.getParticipantInfo();
		await this.getOrCreatePage();
		this.$store.dispatch('submitClaimCOB', {});
	}

	async mounted() {
		this.$store.dispatch('updateLoading', true);
		this.currentPage = 1;
		this.eligibleHsaClaims = await HsaClaims.getHsaEligibleClaims(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.locale
		);
		if (Object.keys(this.eligibleHsaClaims.data).length > 0) {
			this.results = this.eligibleHsaClaims.data;
			this.filteredResults = structuredClone(this.results);
			for (let key in Object.values(this.eligibleHsaClaims.data)) {
				this.totalRows = this.totalRows + Object.values(this.eligibleHsaClaims.data)[key].length;
			}
		} else {
			this.$store.dispatch('isOkToLeaveClaimSubmission', true);
			this.hsaClaimModalShow = true;
		}
		this.$store.dispatch('updateLoading', false);
	}
	async selectClaim(hsaClaim, key) {
		await HsaClaims.clearServices();
		if (hsaClaim.services?.length === 1) {
			await HsaClaims.insertHsaEligibleService(
				hsaClaim.services[0],
				sessionStorage.getItem('email')
			);
		} else {
			hsaClaim.services.forEach(async (service) => {
				await HsaClaims.insertHsaEligibleService(service, sessionStorage.getItem('email'));
			});
		}
		this.claim.hsaEligibleServices = await HsaClaims.getHSAServices(
			sessionStorage.getItem('email')
		);
		this.claim.participantId = key;
		this.claim.lob = hsaClaim.lob;
		await COB.clearCOB();
		await Page.updatePage(this.page, this.claim);
		this.$store.dispatch('HSAClaimSelectDate', hsaClaim.serviceDate);
		this.$router.push({
			name: SUBMIT_CLAIM.SERVICE_LIST
		});
	}
	showClaimNotFoundModal() {
		this.showModal = true;
	}
	modal(value) {
		this.showModal = value;
	}
	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}

	/**
	 * Filter through the HsaEligible claims based on the search criteria the member enters.
	 */
	async filterHsaEligibleClaims(searchCriteria) {
		this.filteredResults = {};
		// if participant Id is not equal to all then filter the results based on participant id.
		if (searchCriteria.participantId !== 'all') {
			for (const [key, value] of Object.entries(this.results)) {
				if (key === searchCriteria.participantId) {
					this.filteredResults[key] = value;
				}
			}
		} else {
			// if all is selected then created a clone of hsa claims. This makes sure the filteredResults isn't just a reference.
			this.filteredResults = structuredClone(this.results);
		}

		if (searchCriteria.benefit !== 'all') {
			for (const [key, value] of Object.entries(this.filteredResults)) {
				this.filteredResults[key] = value.filter((claim) => claim.lob === searchCriteria.benefit);
			}
		}

		// Remove empty object values from the filtered results. This prevents empty tables showing up.
		this.filteredResults = Object.fromEntries(
			Object.entries(this.filteredResults).filter(([_, result]) => result.length > 0)// eslint-disable-line
		);

		if (Object.keys(this.filteredResults).length === 0) {
			this.showNoResults = true;
		}
	}

	async navigateBack() {
		await Page.deleteById(this.pageName);
	}
	/**
	 * This method will check to see if a page object already exists for this screen, if it does it will retrieve it and update the claim object with the value it has for the claims.
	 * If no page exists it will create a new one.
	 */
	async getOrCreatePage() {
		this.page = await Page.getPageById(this.pageName);
		if (this.page === null) {
			// Created a structured clone of the claim object from the last page. This ensures that the claim object is not a reference object.
			this.claim = structuredClone(await Page.getClaimFromLastPage());
			this.page = await Page.createNewPage(this.pageName, sessionStorage.getItem('email'));
		} else {
			this.claim = this.page.claims[0];
		}
	}
}
</script>
<style lang="scss" scoped>
.card {
	border-radius: 0px 10px 10px 10px;
	@media (min-width: 992px) {
		width: 825px;
	}
}
.top-title {
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	font-size: 21px;
}
.pagination-hsa {
	&::v-deep .pagination-nav {
		margin-bottom: -28px;
	}
	@media (min-width: 992px) {
		&::v-deep .pagination-nav,
		&::v-deep .pagination-info {
			margin-left: 190px;
		}
	}
}
.claim-not-found-btn {
	font-weight: $font-regular;
	z-index: 4;
	@media (min-width: 576px) {
		position: fixed;
		bottom: 30px;
		right: 30px;
		box-shadow: 5px 5px 15px #1b1b1b80;
	}
}
.claim-not-found-btn-fr {
	font-weight: $font-regular;
	z-index: 4;
	height: 65px;
	width: 230px;
	@media (min-width: 576px) {
		position: fixed;
		bottom: 30px;
		right: 30px;
	}
}
.footer-container {
	@media (max-width: 576px) {
		background-color: $white;
		position: fixed;
		bottom: 0;
		width: 100%;
		z-index: 4;
		box-shadow: 0px 0px 10px #1b1b1b80;
	}
}
</style>
<i18n>
{
    "en": {
		"title": {
			"step": "HSA Eligible Claims"
		},
        "text" : "Select a claim from the list below and submit for reimbursement through your HSA account.",
        "button" : {
            "claimNotFound": "Claim not found?"
		},
		"error": {
			"noResultsTitle": "0 results found",
			"noResultsText": "Please try a new search.",
			"close": "Close"
		}
    },
    "fr": {
		"title": {
      	"step": "Demandes admissibles au CGS"
    },
        "text" :"Sélectionnez une demande de règlement dans la liste ci-dessous pour soumettre le montant afin qu`il soit remboursé par votre CGS.",
        "button" : {
            "claimNotFound": "Vous ne trouvez pas la demande?"
        },
		"error": {
			"noResultsTitle": "0 résultat trouvé",
			"noResultsText": "Veuillez réessayer.",
			 "close": "Fermer"
		}
    }
}
</i18n>
