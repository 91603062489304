<template>
	<div>
		<BRow>
			<BCol>
				<p class="bold-text text">{{ formattedServiceDate }}</p>
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<p class="bold-text text">{{ claimType }}</p>
			</BCol>
		</BRow>
		<BRow>
			<BCol cols="8" sm="9" md="9" lg="10">
				<p class="label text">{{ $t('amountReimbursed') }}</p>
			</BCol>
			<BCol cols="4" sm="3" md="3" lg="2">
				<p class="currency text">{{ amountReimbursed }}</p>
			</BCol>
		</BRow>
		<BRow>
			<BCol cols="8" sm="9" md="9" lg="10">
				<p class="label text">{{ $t('amountEligible') }}</p>
			</BCol>
			<BCol cols="4" sm="3" md="3" lg="2">
				<p class="currency text">{{ amountEligible }}</p>
			</BCol>
		</BRow>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
// Mixins
import IdMixin from '@/mixins/id';
// Utils
import { formatDateForUi } from '@/utils/date';

// Components
import { BRow, BCol } from 'bootstrap-vue';

// Models

@Component({
	name: 'HsaServiceListItem',
	mixins: [IdMixin],
	components: {
		BRow,
		BCol
	},
	props: {
		value: {
			type: Array,
			default: () => {}
		},
		lobLabels: {
			type: Array,
			default: null
		}
	}
})
export default class HsaServiceListItem extends Vue {
	// ---------------------- Computed Props -------------------------
	get formattedServiceDate() {
		let formattedDate = null;
		if (this.value && this.value[0]?.dateOfService) {
			formattedDate = this.value[0]?.dateOfService?.replaceAll('-', '');
			return formatDateForUi(formattedDate, this.$store.state.i18n.locale);
		} else {
			return null;
		}
	}

	get claimType() {
		let claimType = null;
		this.lobLabels?.map((item) => {
			if (this.value[0]?.lob === item.code) {
				if (this.$root.$i18n.locale === 'en') {
					claimType = item.label_en;
				} else {
					claimType = item.label_fr;
				}
			}
		});
		return claimType;
	}

	get amountReimbursed() {
		let total = 0;
		this.value?.forEach((hsaService) => {
			total = total + Number(hsaService.amountPaid);
		});
		const formatter = new Intl.NumberFormat(`${this.$root.$i18n.locale}-CA`, {
			style: 'currency',
			currency: 'CAD',
			minimumFractionDigits: 2
		});

		if (total != null && !isNaN(total)) {
			let valueToreturn = formatter.format(total);
			return this.$root.$i18n.locale === 'fr' ? valueToreturn.replace('CA', '') : valueToreturn;
		} else {
			return null;
		}
	}

	get amountEligible() {
		let total = 0;
		this.value?.forEach((hsaService) => {
			total = total + Number(hsaService.calculatedEligibleAmount);
		});
		const formatter = new Intl.NumberFormat(`${this.$root.$i18n.locale}-CA`, {
			style: 'currency',
			currency: 'CAD',
			minimumFractionDigits: 2
		});

		if (total != null && !isNaN(total)) {
			let valueToreturn = formatter.format(total);
			return this.$root.$i18n.locale === 'fr' ? valueToreturn.replace('CA', '') : valueToreturn;
		} else {
			return null;
		}
	}
}
</script>
<style lang="scss" scoped>
.bold-text {
	font-family: $josefin-font-family;
	font-size: 17px;
	font-weight: 500;
}
.currency {
	font-family: $lato-font-family;
	font-size: 17px;
	font-weight: 400;
}
.label {
	font-family: $lato-font-family;
	font-size: 17px;
	font-weight: 300;
}
.text {
	margin-bottom: 0.25rem;
}
</style>
<i18n>
{
	"en": {
		"amountReimbursed": "Amount Reimbursed",
        "amountEligible": "Amount Eligible for HSA"
	},
	"fr": {
		"amountReimbursed": "Montant remboursé",
        "amountEligible": "Montant admissible au CGS"
	}
}
</i18n>
