<!--
 * This component is called to show when the user does not have any eligible HSA claims
 -->
<template>
	<div>
		<ErrorModal
			:modal-show="notFound"
			:has-close-button="false"
			:cant-close-on-esc="true"
			no-hide-on-blur
			:error-title="$t('message.title')"
			:error-message="
				$t('message.body.part1') +
				'<br /><br />' +
				$t('message.body.part2') +
				'<br /><br />' +
				$t('message.body.part3')
			"
		>
			<BaseButton
				variant="primary"
				:label="$t('button.no')"
				:automation-id="'btn-no'"
				@click="onClickNo"
			/>
			<BaseButton
				:label="$t('button.yes')"
				:automation-id="'btn-yes'"
				variant="secondary"
				class="ml-2"
				@click="onClick"
			/>
		</ErrorModal>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ErrorModal from '@/components/common/ErrorModal';
import BaseButton from '@/components/common/base/BaseButton';
import * as Routes from '@/constants/Routes.js';
@Component({
	name: 'ClaimNotFound',
	components: {
		ErrorModal,
		BaseButton
	},
	props: {
		notFound: {
			type: Boolean,
			default: false
		}
	}
})
export default class ClaimNotFound extends Vue {
	onClick() {
		this.$router.push({ name: Routes.CLAIM_TYPE });
	}
	onClickNo() {
		this.$emit('modal', false);
	}
}
</script>
<i18n>
{
    "en": {
		"message": {
			"title": "Can’t find your claim in the list?",
			"body": {
				"part1": "Your HSA funds can only be used if a claim was already processed and you are seeking reimbursement for a leftover out-of-pocket amount. ",
				"part2": "If your claim isn't in the list, you will need to submit your claim under your regular benefit plan before you can claim it against your HSA. Please go back to the main page and select the benefit category that best applies to your claim. ",
				"part3": "Do you need to submit this claim under a different benefit type?"
			}
		},
		"button": {
			"no": "No",
			"yes": "Yes"
		}
    },
    "fr": {
		"message" : {
			"title": "Vous ne trouvez pas votre demande de règlement dans la liste?",
			"body": {
				"part1":"Les fonds de votre CGS ne peuvent être utilisés que si votre demande de règlement a déjà été traitée et que vous souhaitez vous faire rembourser les frais excédentaires que vous avez déboursés. ",
				"part2": "Si votre demande de règlement ne figure pas dans la liste, vous devrez la soumettre en vertu de votre régime d'assurance régulier avant de demander un remboursement dans le cadre de votre CGS. Veuillez retourner à la page principale et sélectionner la catégorie de garantie qui s'applique le mieux à votre demande de règlement. ",
				"part3": "Cette demande de règlement doit-elle être soumise sous un autre type de garantie?"
			}
		},
		"button": {
			"no": "Non",
			"yes": "Oui"
		}
	}
}
</i18n>
