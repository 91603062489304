<template>
	<h1
		:id="getUniqueId('ClaimPageHeader')"
		class="claim-page-title"
		:data-test-automation-id="getAutomationId('ClaimPageHeader')"
		:data-tracking-id="getAutomationId('ClaimPageHeader')"
	>
		{{ title }}
	</h1>
</template>

<script>
import IdMixin from '@/mixins/id';

export default {
	name: 'ClaimPageHeader',
	mixins: [IdMixin],
	props: {
		title: {
			type: String,
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>
.claim-page-title {
	font-family: $font-family-regular;
	font-size: 21px;
	font-weight: $font-weight-light;
	margin-top: 20px;
	margin-bottom: 10px;
}
</style>
