<!--**
 * This component is called to show when the user does not have any HSA funds
 *-->
<template>
	<div>
		<ErrorModal
			:modal-show="noClaimModal"
			:has-close-button="false"
			no-hide-on-blur
			:error-title="$t('message.title')"
			:error-message="$t('message.body.part1')"
		>
			<BaseButton
				:label="$t('button.ok')"
				:automation-id="'btn-ok'"
				variant="primary"
				@click="onClick"
			/>
		</ErrorModal>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ErrorModal from '@/components/common/ErrorModal';
import BaseButton from '@/components/common/base/BaseButton';
import * as Routes from '@/constants/Routes.js';
@Component({
	name: 'HsaClaimError',
	components: {
		ErrorModal,
		BaseButton
	},
	props: {
		noClaimModal: {
			type: Boolean,
			default: false
		}
	}
})
export default class HsaEligibleClaimError extends Vue {
	onClick() {
		this.$router.push({ name: Routes.CLAIM_TYPE });
	}
}
</script>
<i18n>
{
    "en": {
		"message": {
			"title": "No HSA Eligible Claims",
			"body": {
				"part1": "Your account does not have any claims that are eligible for HSA."
			}
		},
		"button": {
			"ok": "OK"
		}
    },
    "fr": {
		"message" : {
			"title": "Aucune demande de règlement admissible au CGS",
			"body": {
				"part1":"Votre compte ne contient aucune demande de règlement admissible au CGS pour le moment."
			}
		},
		"button": {
			"ok": "OK"
		}
	}
}
</i18n>
