<template>
	<div class="verify-info-card">
		<BaseCard automation-id="verifyInfo" :title="memberName">
			<BaseDisplayGroup class="email-group" automation-id="member-email" :label="$t('label.email')">
				{{ email }}
			</BaseDisplayGroup>

			<BaseDisplayGroup automation-id="member-phone" :label="$t('label.phone')">
				<address>{{ phoneNumber }}</address>
			</BaseDisplayGroup>

			<BaseButton
				class="p-0"
				automation-id="verify-personal-edit"
				variant="link"
				:label="$t('button.label')"
				@click="editClick"
			/>
		</BaseCard>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseButton from '@/components/common/base/BaseButton.vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseDisplayGroup from '@/components/common/base/BaseDisplayGroup.vue';
import NavigationModal from '@/components/common/NavigationModal';
import { PERSONAL_INFORMATION } from '@/constants/Routes.js';

@Component({
	name: 'VerifyPersonalInfoCard',
	components: {
		BaseCard,
		BaseDisplayGroup,
		NavigationModal,
		BaseButton
	},
	mixins: [IdMixin],
	props: {
		memberName: {
			type: String,
			default: null
		},
		email: {
			type: String,
			default: null
		},
		phoneNumber: {
			type: String,
			default: null
		}
	}
})
export default class VerifyPersonalInfoCard extends Vue {
	get navigationRoute() {
		return PERSONAL_INFORMATION;
	}
	editClick() {
		this.$emit('editClick', [this.navigationRoute, this.$t('navModal.item')]);
	}
}
</script>

<style lang="scss" scoped>
.verify-info-card ::v-deep .display-group {
	margin-top: 0px;
}
.verify-info-card ::v-deep .card-title {
	margin-bottom: 8px;
}
</style>

<i18n>
{
  "en": {
    "label": {
        "email": "Email",
        "phone": "Phone"
    },
	"navModal": {
		"item": "personal information"
	},
	"button": {
        "label": "Edit"
	}
  },
  "fr": {
    "label": {
        "email": "Courriel",
        "phone": "Téléphone"
    },
	"navModal": {
		"item": "coordonnées"
	},
	"button": {
        "label": "Modifier"
	}
  }
}
</i18n>
