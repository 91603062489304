<template>
	<SubmitClaimTemplate v-if="!loading" :percent-complete="95" :step-title="$t('title.step')">
		<BaseForm v-slot="{ invalid }" automation-id="claim-summary-form'">
			<BaseCard :class="isHSA ? '' : 'summary-card'">
				<div v-if="isPractitioner || isHSA || isVision">
					<ProviderCard
						class="mb-4"
						:provider="provider"
						:member="getMember(claim.participantId)"
						:show-provider="provider && !claim.isProviderNotFound && isPractitioner"
						automation-id="claim-summary-provider"
					/>
					<hr />
				</div>
				<div v-if="!isVision">
					<div v-for="(service, index) in services" :key="service.id">
						<template v-if="isPWA">
							<PwaServiceListItem
								:value="service"
								:member="getMember(claim.participantId)"
								class="service-list-item"
								:automation-id="`claim-summary-pwa-service-${index}`"
							/>
						</template>
						<template v-else-if="isDrugsOrDental">
							<DrugDentalServiceListItem
								:value="service"
								:member="getMember(service.participant)"
								:hide-buttons="true"
								class="service-list-item"
								:automation-id="`claim-summary-drug-dental-service-${index}`"
							/>
						</template>
						<template v-else-if="isHealth">
							<HealthServiceListItem
								:value="service"
								:member="getMember(service.participant)"
								:hide-buttons="true"
								class="service-list-item"
								:automation-id="`claim-summary-health-service-${index}`"
							/>
						</template>
						<template v-else>
							<ServiceListItem
								:value="service"
								class="service-list-item"
								:hide-buttons="true"
								:automation-id="`claim-summary-service-${index}`"
							/>
						</template>
						<hr class="mt-2" />
					</div>
				</div>
				<template v-if="isHSA">
					<HsaServiceListItem
						:value="HsaServices"
						:lob-labels="labels"
						class="service-list-item"
						:automation-id="`claim-summary-hsa-service`"
					/>
					<hr class="mt-2" />
				</template>
				<template v-if="isLifeOrDisablity">
					<h3>{{ $t('title.claiming') }}</h3>
					<ul class="mb-3">
						<li v-if="claim.hasLADDAccidentalDeath" class="list-item">{{ $t('label.life') }}</li>
						<li v-if="claim.hasLADDAccidentalDismemberment" class="list-item">
							{{ $t('label.accidentDismemberment') }}
						</li>
						<li v-if="claim.hasLADDTerminalIllness" class="list-item">
							{{ $t('label.terminalIllness') }}
						</li>
						<li v-if="claim.hasDCIShortTermDisability" class="list-item">
							{{ $t('label.shortTermDisability') }}
						</li>
						<li v-if="claim.hasDCILongTermDisability" class="list-item">
							{{ $t('label.longTermDisability') }}
						</li>
						<li v-if="claim.hasDCICriticalIllness" class="list-item">
							{{ $t('label.criticalIllness') }}
						</li>
					</ul>
					<hr class="mt-2" />
				</template>
				<div v-if="attachedDocumentsCount > 0">
					<h2>{{ $t('label.attachment') }}</h2>
					<div>{{ attachedDocumentsCount }}{{ attachedDocumentLabel }}</div>
				</div>

				<div v-if="claimComments" class="mt-4 comments">
					<h2>{{ $t('label.comments') }}</h2>
					<div>{{ claimComments }}</div>
				</div>
			</BaseCard>
			<BaseCheckBox
				checkbox-name="terms-condition"
				class="checkbox mb-4"
				:checked="acceptTerms"
				:label="$t('label.termsAndConditions')"
				:validation-rules="{ required: { allowFalse: false } }"
				:validation-messages="{ required: $t('error.required') }"
				required
				:automation-id="'claim-summary'"
				@input="updateChecked"
			>
				<TermsAndConditionModal
					:terms-and-condition="termsAndConditionContent"
					:title="termsAndConditionContent.title"
					:automation-id="'claim-summary'"
				/>
			</BaseCheckBox>

			<WizardButtonBar
				:disable-next="invalid"
				:next-button-label="$t('label.submit')"
				@navigate-back="navigateBack()"
				@navigate-next="togglePaymentModal"
			></WizardButtonBar>
			<ErrorModal
				automation-id="multiple-payments-modal-button"
				:error-title="$t('modal.title')"
				:error-message="$t('modal.description')"
				:modal-show="showPaymentModal"
				no-hide-on-blur
				@change="togglePaymentModal"
			>
				<BaseButton
					automation-id="multiple-payments-modal-button"
					:label="$t('modal.button')"
					variant="primary"
					@click="navigateNext()"
				>
				</BaseButton>
			</ErrorModal>
		</BaseForm>
	</SubmitClaimTemplate>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
// components
import SubmitClaimTemplate from './SubmitClaimTemplate.vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseForm from '@/components/common/base/BaseForm';
import BaseCheckBox from '@/components/common/base/BaseCheckBox';
import BaseButton from '@/components/common/base/BaseButton';
import WizardButtonBar from '@/components/wizard/WizardButtonBar.vue';
import TermsAndConditionModal from '@/components/termsAndConditions/TermsAndConditionModal.vue';
import ProviderCard from '@/components/claim/claimService/ProviderCard';
import ServiceListItem from '@/components/claim/claimService/ServiceListItem';
import PwaServiceListItem from '@/components/claim/claimService/PwaServiceListItem';
import HsaServiceListItem from '@/components/claim/claimService/HsaServiceListItem';
import DrugDentalServiceListItem from '@/components/claim/claimService/DrugDentalServiceListItem';
import HealthServiceListItem from '@/components/claim/claimService/HealthServiceListItem';
import ErrorModal from '@/components/common/ErrorModal';
// models
import Claim from '@/models/claims/Claims';
import Page from '@/models/claims/Page';
import Dependent from '@/models/Dependent';
import MemberCardInfo from '@/models/MemberCardInfo';
import HsaClaims from '@/models/claims/HsaClaims';
// Utils
import { formatDateForUi } from '@/utils/date';
// Constants
import * as claimTypes from '@/constants/ClaimTypes';
import { SUBMIT_CLAIM } from '@/constants/Routes.js';

@Component({
	name: 'ClaimSummary',
	components: {
		BaseForm,
		BaseButton,
		BaseCheckBox,
		BaseCard,
		SubmitClaimTemplate,
		WizardButtonBar,
		TermsAndConditionModal,
		ProviderCard,
		ServiceListItem,
		PwaServiceListItem,
		DrugDentalServiceListItem,
		HealthServiceListItem,
		HsaServiceListItem,
		ErrorModal
	}
})
export default class ClaimSummary extends Vue {
	page = {};
	pageName = 'claimSummary';
	claim = {};
	member = {
		firstName: null,
		fullName: null,
		dob: null
	};
	HsaServices = null;
	labels = null;
	acceptTerms = null;
	termsAndConditionContent = { title: '' };
	participants = null;
	loading = true;
	showPaymentModal = false;

	/**
	 * return provider information
	 */
	get provider() {
		return this.claim?.benefitProvider;
	}
	// Return list of services for service list if any exist.
	get services() {
		if (this.claim?.benefitServices && this.claim?.benefitServices.length !== 0) {
			return this.claim.benefitServices;
		} else if (this.claim?.manualClaimServiceItems) {
			return this.claim.manualClaimServiceItems;
		} else {
			return [];
		}
	}

	get attachedDocumentsCount() {
		return this.$store.state.submitClaim.attachedFiles.length;
	}

	get attachedDocumentLabel() {
		return this.attachedDocumentsCount > 1
			? this.$t('label.manyAttachment')
			: this.$t('label.oneAttachment');
	}

	get claimComments() {
		return this.claim?.hasComments && this.claim?.comments ? this.claim.comments : null;
	}

	get isPractitioner() {
		return this.claim?.claimType === claimTypes.PRACTITIONER ? true : false;
	}
	get isPWA() {
		return this.claim?.claimType === claimTypes.PWA ? true : false;
	}
	get isHSA() {
		return this.claim?.claimType === claimTypes.HSA ? true : false;
	}
	get isVision() {
		return this.claim?.claimType === claimTypes.VISION ? true : false;
	}
	get isLifeOrDisablity() {
		return this.claim?.claimType === claimTypes.DCI || this.claim?.claimType === claimTypes.LADD
			? true
			: false;
	}
	get isDrugsOrDental() {
		return this.claim?.claimType === claimTypes.DRUGS || this.claim.claimType === claimTypes.DENTAL;
	}
	get isHealth() {
		return this.claim?.claimType === claimTypes.HEALTH;
	}

	// lifecycle methods
	async mounted() {
		this.$store.dispatch('updateLoading', true);

		await this.getOrCreatePage();
		await this.getMemberInfo();
		await this.getResourceUrls();

		if (this.isHSA) {
			this.HsaServices = this.claim?.hsaEligibleServices;
			await this.getlabels();
		}

		this.loading = false;
		this.$store.dispatch('updateLoading', false);
	}

	/**
	 * Retrieve the member name and dob using the claim particpant ID.
	 * This displays in the provider card.
	 */
	async getMemberInfo() {
		Dependent.getDependents(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		)?.then((response) => {
			this.participants = response?.data;
		});
	}

	/**
	 * This method retrieves the participants first name and date of birth to be used in the list of service items.
	 */
	getMember(participantId) {
		const participant = this.participants?.find(
			(participant) => participant.participantId === participantId
		);
		let member = {};
		member.firstName = participant?.firstName;
		member.fullName = `${participant?.firstName} ${participant?.lastName}`;
		member.dob = formatDateForUi(participant?.dateOfBirth, this.$store.state.i18n.locale);
		return member;
	}

	/**
	 * This method gets the resourse urls from the api to know which
	 * file in aws to get the terms and condition content from.
	 */
	async getResourceUrls() {
		MemberCardInfo.getResourceUrlsByEmail(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale
		)?.then((response) => {
			if (response && (response.status === 201 || response.status === 200)) {
				let secureUrl = response.data.json_claimTermsUrl.replace('http://', 'https://');
				fetch(secureUrl)
					.then((response) => response.json())
					.then((data) => (this.termsAndConditionContent = data));
			}
		});
	}

	async getlabels() {
		this.labels = await HsaClaims.getLobLabels(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.locale
		);
	}

	updateChecked(value) {
		this.acceptTerms = value;
	}

	async navigateNext() {
		this.$store.dispatch('updateLoading', true);

		try {
			// Submit claim
			let claimResponse = await Claim.submitClaim(
				this.claim,
				this.$store.state.submitClaim.attachedFiles,
				sessionStorage.getItem('email'),
				this.$root.$i18n.locale,
				sessionStorage.getItem('apiToken')
			);
			this.$store.dispatch('updateLoading', false);
			if (claimResponse.status === 200) {
				this.$store.dispatch('isOkToLeaveClaimSubmission', true);
				this.$router.push({
					name: SUBMIT_CLAIM.SUBMIT_CLAIM_SUCCESS,
					params: { claimType: this.claim.claimType }
				});
			}
		} catch (error) {
			this.$store.dispatch('updateLoading', false);
			this.$store.dispatch('error', {
				name: 'generic'
			});
		}
		this.togglePaymentModal();
	}

	async navigateBack() {
		Page.deleteById(this.pageName);
	}

	togglePaymentModal() {
		this.showPaymentModal = !this.showPaymentModal;
	}

	/**
	 * This method will check to see if a page object already exists for this screen, if it does it will retrieve it and update the claim object with the value it has for the claims.
	 * If no page exists it will create a new one.
	 */
	async getOrCreatePage() {
		this.page = await Page.getPageById(this.pageName);
		if (this.page === null) {
			this.claim = await Page.getClaimFromLastPage();
			this.page = await Page.createNewPage(this.pageName, sessionStorage.getItem('email'));
		} else {
			this.claim = this.page?.claims[0];
		}
	}
}
</script>

<style lang="scss" scoped>
h2 {
	font-size: 24px;
	line-height: normal;
}
.service-list-item {
	padding: 0px;
}
.summary-card {
	width: 100%;
	max-width: 540px;
}
.checkbox ::v-deep .form-control {
	font-family: $font-family-headline;
	background-color: initial;
	padding-top: 2px;
	padding-right: 5px;
	& > label {
		font-size: 17px;
	}
}
.comments {
	width: 80%;
	@media (max-width: 768px) {
		width: 100%;
	}
}
.list-item {
	padding-left: 15px;
}
</style>

<i18n lang="json">
{
	"en": {
		"title": {
			"step": "Claim Summary",
			"claiming": "Claims for"
		},
		"label": {
			"attachment": "Attachments",
			"oneAttachment": " document/image has been attached.",
			"manyAttachment": " documents/images have been attached.",
			"termsAndConditions": "I agree to the ",
			"comments": "Comments",
			"submit": "Submit",
			"life": "Life Insurance or Accidental Death",
			"accidentDismemberment": "Accidental Dismemberment",
			"terminalIllness": "Terminal Illness",
			"shortTermDisability": "Short-Term Disability or Salary Continuance",
			"longTermDisability": "Long Term Disability",
			"criticalIllness": "Critical Illness"
		},
		"modal": {
			"title": "How will I receive my payment?",
			"description": "You may see your claim reimbursement arrive in multiple deposits. This is a standard part of the way we process claims.",
			"button": "OK"
		},
		"error": {
			"required": "Please accept the Terms and Conditions to continue."
		}
	},
	"fr": {
		"title": {
			"step": "Sommaire de la demande de règlement",
			"claiming": "Demandes de règlement pour"
		},
		"label": {
			"attachment": "Pièces jointes",
			"oneAttachment": " document joint",
			"manyAttachment": " documents joints",
			"termsAndConditions": "J'accepte les ",
			"comments": "Commentaires",
			"submit": "Soumettre",
			"life": "Assurance vie ou assurance décès par accident",
			"accidentDismemberment": "Assurance mutilation par accident",
			"terminalIllness": "Maladie en phase terminale",
			"shortTermDisability": "Invalidité de courte durée ou maintien du salaire",
			"longTermDisability": "Invalidité de longue durée",
			"criticalIllness": "Maladies graves"
		},
		"modal": {
			"title": "Comment vais-je recevoir mon paiement?",
			"description": "Le remboursement de votre demande de règlement pourrait être versé en plusieurs fois. Il s'agit de notre procédure habituelle pour le traitement des demandes de règlement.",
			"button": "OK"
		},
		"error": {
			"required": "Vous devez accepter les modalités avant de continuer."
		}
	}
}
</i18n>
