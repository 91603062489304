<!-- 
	Wizard Progress Bar

  Displays a horizontal Bootstrap progress bar.  
-->

<template>
	<BProgress
		:id="getUniqueId('wizardProgressBar')"
		:data-test-automation-id="getAutomationId('wizardProgressBar')"
		:data-tracking-id="getAutomationId('wizardProgressBar')"
		:max="100"
		height="8px"
		class="mb-4 wizard-progress"
		role="progressbar"
		:aria-label="$t('aria.progressBarLabel')"
		aria-valuemin="0"
		aria-valuemax="100"
		:aria-valuenow="percent"
	>
		<BProgressBar :value="percent" class="wizard-progress-bar" aria-hidden="true"></BProgressBar>
	</BProgress>
</template>

<script>
import IdMixin from '@/mixins/id';
import { BProgress, BProgressBar } from 'bootstrap-vue';

export default {
	name: 'WizardProgressBar',
	components: {
		BProgress,
		BProgressBar
	},
	mixins: [IdMixin],
	props: {
		/** The percentage completed to show on the progress bar */
		percent: {
			type: Number,
			required: true,
			validator: (percent) => percent >= 0 && percent <= 100
		}
	}
};
</script>

<style lang="scss" scoped>
.wizard-progress {
	background-color: $gray-light;
	border-radius: 10px;
}

.wizard-progress-bar {
	background-color: $light-blue;
	border-radius: 10px;
}
</style>

<i18n>
{
  "en": {
    "aria": {
      "progressBarLabel": "Progress Bar"
    }
  },
  "fr": {
    "aria": {
      "progressBarLabel": "Barre de progression"
    }
  }
}
</i18n>
