<template>
	<BaseRadioButtonGroup
		:value="value"
		v-bind="$attrs"
		:validation-rules="{ required: isRequired }"
		:validation-messages="{ required: requiredErrorMsgComp }"
		:options="options"
		:label="label"
		:accessible-required-label="$t('error.accessibleRequired')"
		:stacked="true"
		:optional-label="optionalLabel"
		:automation-id="getAutomationId('yesnotoggle')"
		@input="onInput"
		><slot></slot
	></BaseRadioButtonGroup>
</template>

<script>
/**
 * This component renders a Yes No toggle
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseRadioButtonGroup from '@/components/common/base/BaseRadioButtonGroup.vue';
import { required } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
extend('required', required);
// @vue/component
@Component({
	name: 'YesNoToggle',
	components: {
		BaseRadioButtonGroup
	},
	mixins: [IdMixin],
	inheritAttrs: false,
	/**
	 * this defines how the v-model is define.
	 */
	model: {
		prop: 'value',
		event: 'input'
	},
	props: {
		/**
		 * value is the value that will be used by this component to populate the input fields.
		 */
		value: {
			type: Boolean,
			default: null
		},
		/**
		 * This prop determines label displayed above the radio buttons.
		 */
		label: {
			type: String,
			default: ''
		},
		/**
		 * This prop determines the optional label displayed above the radio buttons.
		 */
		optionalLabel: {
			type: String,
			default: ''
		},
		/**
		 * Determines if the component is required.
		 */
		isRequired: {
			type: Boolean,
			default: false
		},
		/**
		 * Determines if error message to display when the component
		 * is required, but does not have a value set.
		 */
		requiredErrorMsg: {
			type: String,
			default: function () {
				return this.$t('error.required');
			}
		},
		/**
		 * Test automation id is used by the test automation Id suite to identify specfic components.
		 */
		automationId: {
			type: String,
			required: true
		}
	}
})
export default class YesNoToggle extends Vue {
	/**
	 * These computed props check if an error message was passed in,
	 * if it wasn't then it used a default error message.
	 */
	get requiredErrorMsgComp() {
		return this.requiredErrorMsg ? this.requiredErrorMsg : this.$t('error.required');
	}
	/**
	 * Returns the list of options
	 * @returns {array}
	 */
	get options() {
		return [
			{ value: true, text: this.$t('value.yes') },
			{ value: false, text: this.$t('value.no') }
		];
	}
	/**
	 * Handle input event.
	 * @param {string} value The input value.
	 */
	onInput(value) {
		this.$emit('input', value);
	}
}
</script>

<i18n>
{
	"en": {
		"value": {
			"yes": "Yes",
			"no": "No"
		},
		"error": {
			"required": "Please choose an option",
			"accessibleRequired": "Required, please choose an option"
		}
	},
	"fr": {
		"value": {
			"yes": "Oui",
			"no": "Non"
		},
		"error": {
			"required": "Veuillez choisir une option.",
			"accessibleRequired": "Requise, veuillez choisir une option."
		}
	}
}
</i18n>
