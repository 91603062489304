<template>
	<div class="service-list-item">
		<BRow>
			<BCol>
				<p class="service-date mb-1">{{ formattedServiceDate }}</p>
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<p class="mb-1">
					<span id="member-name" class="name">{{ member.fullName }}</span>
					<!-- eslint-disable-next-line -->
			        <span id="member-dob" class="member-dob">({{ member.dob }})</span> 
				</p>
			</BCol>
		</BRow>

		<BRow v-if="value && value.specify">
			<BCol cols="11"
				><p class="text">{{ $t('label.other') }} {{ value.specify }}</p></BCol
			>
		</BRow>
		<BRow v-else-if="value && value.whatIsClaimFor">
			<BCol cols="11"
				><p :class="!hideButtons ? 'text' : null">{{ value.whatIsClaimFor }}</p></BCol
			>
		</BRow>
		<BRow v-if="!hideButtons">
			<BaseButton
				:label="$t('button.edit')"
				:aria-label="$t('button.edit')"
				class="button button-edit p-0"
				variant="link"
				:disabled="disableButtons"
				:automation-id="getAutomationId('service-list-edit')"
				@click="editService()"
			/>
			<div v-if="!hideRemove">
				<BaseButton
					:label="$t('button.remove')"
					:aria-label="$t('button.remove')"
					class="button p-0"
					variant="link"
					:disabled="disableButtons"
					:automation-id="getAutomationId('service-list-remove')"
					@click="removeService()"
				/>
			</div>
		</BRow>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
// Mixins
import IdMixin from '@/mixins/id';
// Utils
import { formatDateForUi } from '@/utils/date';

// Components
import { BRow, BCol } from 'bootstrap-vue';
import BaseButton from '@/components/common/base/BaseButton.vue';

// Models

@Component({
	name: 'HealthServiceListItem',
	mixins: [IdMixin],
	components: {
		BaseButton,
		BRow,
		BCol
	},
	props: {
		value: {
			type: Object,
			default: () => {}
		},
		disableButtons: {
			type: Boolean,
			default: false
		},
		member: {
			type: Object,
			default: () => {}
		},
		// Prop used to determine if we hide the remvoe button from the service list item.
		hideRemove: {
			type: Boolean,
			default: false
		},
		hideButtons: {
			type: Boolean,
			default: false
		}
	}
})
export default class HealthServiceListItem extends Vue {
	// ---------------------- Computed Props -------------------------
	get formattedServiceDate() {
		if (this.value?.dateOfService) {
			this.value.dateOfService = this.value?.dateOfService?.replaceAll('-', '');
			return formatDateForUi(this.value.dateOfService, this.$store.state.i18n.locale);
		} else {
			return null;
		}
	}

	editService() {
		this.$emit('edit');
	}

	removeService() {
		this.$emit('remove');
	}
}
</script>
<style lang="scss" scoped>
.service-list-item {
	padding: 30px;
}
.service-date {
	font-family: $josefin-font-family;
	font-size: 17px;
	font-weight: 500;
}
div,
p {
	font-weight: 300;
}
.name {
	font-weight: 400;
	font-size: 18px;
	line-height: 18px;
}
.member-dob {
	font-weight: 300;
	font-size: 18px;
	margin-left: 0.5em;
}
.button {
	font-family: $lato-font-family;
	font-weight: 400;
	text-decoration: underline;
}
.text {
	margin-bottom: 0.25rem;
}
.button-edit {
	margin-left: 15px;
	margin-right: 15px;
}
</style>
<i18n>
{
	"en": {
		"label": {
			"other": "Other:"
		},
		"button": {
            "edit": "Edit",
            "remove": "Remove"
        }
	},
	"fr": {
		"label": {
			"other": "Autre:"
		},
		"button": {
            "edit": "Modifier",
            "remove": "Supprimer"
        }
	}
}
</i18n>
